import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core/";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountVerification(props) {
  const { open, handleSubmit, handleClose, handleChange, email } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={Paper}
      PaperProps={{ square: true }}
      TransitionComponent={Transition}
    >
      <DialogTitle>Reset Password</DialogTitle>
      <DialogContent>
        <Typography text="primary">
          Enter your email address below to generate a password reset link
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            required
            fullWidth
            autoFocus
            label="Email"
            value={email}
            margin="normal"
            name="resetEmail"
            variant="outlined"
            autoComplete="email"
            onChange={handleChange}
          />
        </form>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <IconButton onClick={handleClose}>
          <CloseSharpIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            handleSubmit(email);
            handleClose(e);
          }}
        >
          <CheckSharpIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
