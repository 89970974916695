import React, { Component } from "react";
import { connect } from "react-redux";

import { setPage } from "../store/actions/page";

export default function checkAuth(ComponentToBeRendered, options) {
  class Authenticate extends Component {
    componentDidMount() {
      const { setPage, currentUser, history } = this.props;
      if (currentUser.isAuthenticated) {
        history.push("/");
      } else {
        if (options.title) {
          setPage(options.title);
        }
      }
    }
    render() {
      return <ComponentToBeRendered {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return { currentUser: state.currentUser };
  }

  return connect(mapStateToProps, { setPage })(Authenticate);
}
