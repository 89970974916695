import React, { Component } from "react";

import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core/";

import store from "../../store";
import { passwordReset } from "../../store/actions/passwordReset";
import { addSnackbar } from "../../store/actions/snackbars";

export default class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      passwordVerification: "",
      id: this.props.match.params.id,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { history } = this.props;
    const { password, passwordVerification } = this.state;

    if (password !== passwordVerification) {
      store.dispatch(
        addSnackbar({ message: "Passwords do not match!" }, "error")
      );
    } else {
      await store.dispatch(passwordReset(this.state, history));
    }
  };

  render() {
    return (
      <Container component={Paper} square maxWidth="xs">
        <Typography text="primary" component="h1" variant="h5">
          Reset Password
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              onChange={this.handleChange}
              value={this.state.password}
              inputProps={{
                pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*|.*[#$^+=!*()@%&]).{8,}",
                title:
                  "Minimum 8 characters with at least one lowercase, one uppercase and one number or symbol",
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              type="password"
              variant="outlined"
              label="Verify Password"
              name="passwordVerification"
              onChange={this.handleChange}
              value={this.state.passwordVerification}
              inputProps={{
                pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*|.*[#$^+=!*()@%&]).{8,}",
                title:
                  "Minimum 8 characters with at least one lowercase, one uppercase and one number or symbol",
              }}
            />
          </Grid>
          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign In
          </Button>
        </form>
      </Container>
    );
  }
}
