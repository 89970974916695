import React from "react";
import { Link } from "react-router-dom";

import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import DeveloperBoardSharpIcon from "@material-ui/icons/DeveloperBoardSharp";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import PeopleAltSharpIcon from "@material-ui/icons/PeopleAltSharp";
import PersonSharpIcon from "@material-ui/icons/PersonSharp";
import TimerSharpIcon from "@material-ui/icons/TimerSharp";

import Logo from "../../img/logo_transparent.png";
import store from "../../store";
import { logout } from "../../store/actions/auth";

export default function DrawerList(props) {
  const classes = useStyles();
  const { history, currentUser } = props;
  const { isAuthenticated, username, accountType } = currentUser;
  return (
    <div className={classes.list}>
      <List className={classes.logo}>
        <ListItem component={Link} to="/">
          <ListItemIcon className={classes.center}>
            <img className={classes.logoImg} src={Logo} alt="AWES" />
          </ListItemIcon>
        </ListItem>
      </List>

      <Divider />

      {isAuthenticated && (
        <React.Fragment>
          <List>
            {accountType === "Admin" && (
              <ListItem
                component={Link}
                to="/users"
                className={classes.item}
                button
              >
                <ListItemIcon>
                  <PeopleAltSharpIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
            )}
            <ListItem
              component={Link}
              to="/projects"
              className={classes.item}
              button
            >
              <ListItemIcon>
                <DeveloperBoardSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Projects" />
            </ListItem>
          </List>
          {(accountType === "Admin" || accountType === "Estimator") && (
            <ListItem
              component={Link}
              to={"/timeLogs"}
              className={classes.item}
              button
            >
              <ListItemIcon>
                <TimerSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Time Tracking" />
            </ListItem>
          )}

          <div className={classes.grow}></div>
          <Divider />

          <List>
            <ListItem
              className={classes.item}
              component={Link}
              to="/settings"
              button
            >
              <ListItemIcon color="inherit">
                <PersonSharpIcon color="inherit" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span style={{ textTransform: "capitalize" }}>
                    {username}
                  </span>
                }
              />
            </ListItem>
            <ListItem
              className={classes.item}
              component={Link}
              to="/login"
              onClick={() => {
                store.dispatch(logout(history));
              }}
              button
            >
              <ListItemIcon>
                <ExitToAppSharpIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>

          <Divider />
          <List>
            <ListItem className={classes.center} button>
              <ListItemText
                primary={
                  <a
                    href="https://www.awes.com.au"
                    alt="awes"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    www.awes.com.au
                  </a>
                }
              />
            </ListItem>
          </List>
        </React.Fragment>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  logoImg: { width: 175, height: 70 },
  list: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  item: {
    paddingBottom: 0,
    "& svg": {
      color: theme.palette.secondary.contrastText,
    },
  },
  center: {
    margin: "0 auto",
    textAlign: "center",
  },
  link: {
    color: theme.palette.secondary.contrastText,
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
}));
