import React from "react";

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AddSharpIcon from "@material-ui/icons/AddSharp";

import Actions from "../Common/Actions";
import { show } from "../Common/helperFunctions";

export default function ProjectTable(props) {
  const classes = useStyles();
  const { currentUser, projects, openForm, handleFilter, filter } = props;
  return (
    <TableContainer component={Paper} square>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          Projects
        </Typography>
        <FormControl style={{ width: "200px" }}>
          <InputLabel id="filter">filter</InputLabel>
          <Select labelId="filter" value={filter} onChange={handleFilter}>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Open">Open</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </Select>
        </FormControl>
        {currentUser.accountType !== "Estimator" && (
          <Tooltip title="Add Project">
            <IconButton
              onClick={() => openForm(false)}
              aria-label="add Project"
            >
              <AddSharpIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>Company</strong>
            </TableCell>
            <TableCell>
              <strong>Reference Number</strong>
            </TableCell>
            <TableCell>
              <strong>Address</strong>
            </TableCell>
            <TableCell>
              <strong>Status</strong>
            </TableCell>
            {currentUser.accountType !== "Estimator" && (
              <TableCell align="center">
                <strong>Actions</strong>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {projects.map((project, i) => (
            <TableRow
              hover
              key={i}
              className={classes.tableRow}
              onClick={(e) => show(e, props, "projects", project._id)}
            >
              <TableCell
                scope="row"
                component="th"
                style={{ textTransform: "capitalize" }}
              >
                {project.name}
              </TableCell>
              <TableCell>{project.createdBy.company}</TableCell>
              <TableCell>{project.number}</TableCell>
              <TableCell>{project.address}</TableCell>
              <TableCell>{project.status}</TableCell>
              {currentUser.accountType !== "Estimator" && (
                <TableCell align="center">
                  <Actions {...props} model="Projects" item={project} />
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  tableRow: {
    cursor: "Pointer",
  },
}));
