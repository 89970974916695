import React, { Component } from "react";
import { connect } from "react-redux";

import { Hidden } from "@material-ui/core/";

import {
  deleteProject,
  getProjects,
  postProject,
  putProject,
} from "../../store/actions/projects";
import DeleteDialog from "../Common/DeleteDialog";
import { ProjectForm } from "./Form";
import ProjectGrid from "./Grid";
import ProjectTable from "./Table";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: false,
      filter: "Open",
      openForm: false,
      openDelete: false,
    };
  }

  async componentDidMount() {
    const { currentUser, getProjects } = this.props;

    const { accountType, id } = currentUser;
    const query =
      accountType === "Admin"
        ? {}
        : accountType === "Estimator"
        ? { estimator: id }
        : { createdBy: id };
    await getProjects(query);
  }

  toggleForm = (project) => {
    this.setState({
      project,
      openForm: !this.state.openForm,
    });
  };

  toggleDeleteForm = (project) => {
    this.setState({
      project,
      openDelete: !this.state.openDelete,
    });
  };

  handleDelete = async () => {
    const { project } = this.state;
    const { history, deleteProject } = this.props;
    await deleteProject(history, project._id);
    this.setState({
      project: false,
      openDelete: false,
    });
  };

  handleFilter = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      filter: value,
    });
  };

  filterProjects = (projects) => {
    let { filter } = this.state;
    if (filter === "All") {
      return projects;
    } else {
      return projects.filter((project) => project.status === filter);
    }
  };

  render() {
    const { projects } = this.props;
    const { openForm, openDelete, project, filter } = this.state;

    projects.sort((a, b) => a.name.localeCompare(b.name));
    const filteredProjects = this.filterProjects(projects);

    return (
      <React.Fragment>
        <Hidden mdUp>
          <ProjectGrid
            {...this.props}
            filter={filter}
            openForm={this.toggleForm}
            projects={filteredProjects}
            handleFilter={this.handleFilter}
            openDeleteForm={this.toggleDeleteForm}
          />
        </Hidden>

        <Hidden smDown>
          <ProjectTable
            {...this.props}
            filter={filter}
            openForm={this.toggleForm}
            projects={filteredProjects}
            handleFilter={this.handleFilter}
            openDeleteForm={this.toggleDeleteForm}
          />
        </Hidden>

        <ProjectForm
          {...this.props}
          open={openForm}
          project={project}
          handleClose={this.toggleForm}
        />

        <DeleteDialog
          open={openDelete}
          name={project.name}
          handleDelete={this.handleDelete}
          handleClose={this.toggleDeleteForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects: state.projects.list,
    currentUser: state.currentUser,
    timestamp: state.projects.timestamp,
  };
}

export default connect(mapStateToProps, {
  putProject,
  getProjects,
  postProject,
  deleteProject,
})(Projects);
