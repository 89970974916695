import { createTheme } from "@material-ui/core/styles";

export const awesTheme = createTheme({
  palette: {
    background: {
      paper: "#fff",
      default: "#F5F5F6",
    },
    primary: {
      main: "#2f5896",
      light: "#6384c7",
      dark: "#003068",
      contrastText: "#fff",
    },
    secondary: {
      main: "#85ddf4",
      light: "#b9ffff",
      dark: "#50abc1",
      contrastText: "#000",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
      },
    },
    MuiMenu: {
      paper: {
        minWidth: 200,
        borderRadius: 0,
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          color: "#003068",
          "& svg": {
            color: "#003068",
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 35,
      },
    },
  },
});
