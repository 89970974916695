import React from "react";

import store from "../../store";
import { verifyEmail } from "../../store/actions/auth";

async function actions(props) {
  await store.dispatch(verifyEmail(props.match.params.id, props.history));
}

export default function EmailVerification(props) {
  actions(props);
  return <React.Fragment />;
}
