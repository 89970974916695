import moment from "moment";
import React from "react";

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import DescriptionSharpIcon from "@material-ui/icons/DescriptionSharp";
import FolderSharpIcon from "@material-ui/icons/FolderSharp";
import LinkSharpIcon from "@material-ui/icons/LinkSharp";

import ContextMenu from "./ContextMenu";
import EnhancedTableHead from "./TableHead";
import EnhancedToolbar from "./Toolbar";

const url =
  process.env.NODE_ENV === "development" ? "http://localhost:3000" : "";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const initialContext = {
  mouseX: null,
  mouseY: null,
  content: false,
};

export default function EnhancedTable(props) {
  const classes = useStyles();

  const { activeDirectory, project, setActiveDirectory } = props;

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [selected, setSelected] = React.useState(false);
  const [context, setContext] = React.useState(initialContext);

  const { contents } = activeDirectory;

  const handleClick = (e, content, i) => {
    e.preventDefault();
    setSelected(i);
    setContext({
      content,
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  };

  const handleClose = () => {
    setSelected(false);
    setContext(initialContext);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleMain = (content) => {
    if (content.type === "file") {
      let download = document.createElement("a");
      download.setAttribute("download", true);
      download.href = `${url}/api/download/${project._id}/${content._id}`;
      download.style.display = "none";
      document.body.appendChild(download);
      download.click();
      document.body.removeChild(download);
    } else if (content.type === "folder") {
      setActiveDirectory(content);
      setSelected(false);
    } else if (content.type === "link") {
      let link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = content.url;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <TableContainer className={classes.root} component={Paper} square>
      <EnhancedToolbar {...props} />
      <Table size="small">
        <EnhancedTableHead
          order={order}
          classes={classes}
          orderBy={orderBy}
          rowCount={contents.length}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(contents, getComparator(order, orderBy)).map(
            (content, i) => {
              const labelId = `enhanced-table-checkbox-${i}`;

              return (
                <TableRow
                  key={i}
                  tabIndex={-1}
                  onClick={() => setSelected(i)}
                  onDoubleClick={() => handleMain(content)}
                  onContextMenu={(e) => handleClick(e, content, i)}
                  className={i === selected ? classes.selected : classes.row}
                >
                  <TableCell component="th" id={labelId} scope="row">
                    <ListItem disableGutters dense>
                      <ListItemIcon>
                        {content.type === "folder" ? (
                          <FolderSharpIcon />
                        ) : content.type === "file" ? (
                          <DescriptionSharpIcon />
                        ) : content.type === "link" ? (
                          <LinkSharpIcon />
                        ) : null}
                      </ListItemIcon>
                      <ListItemText primary={content.name} />
                    </ListItem>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary">
                      {content.updatedAt
                        ? moment(content.updatedAt).format("DD MMM YYYY")
                        : "-"}
                      {content.updatedBy && (
                        <span style={{ textTransform: "capitalize" }}>
                          {" "}
                          {content.updatedBy.username}
                        </span>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" color="textSecondary">
                      {content.length
                        ? Math.round(content.length / 100) / 10 + "KB"
                        : "-"}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
      <ContextMenu
        {...props}
        context={context}
        handleMain={handleMain}
        handleClose={handleClose}
      />
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "50vh",
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  input: {
    display: "none",
  },
  row: {
    cursor: "default",
  },
  selected: {
    cursor: "default",
    backgroundColor: theme.palette.action.selected,
  },
  cell: {
    display: "flex",
    height: "100%",
  },
  icon: {
    paddingRight: 25,
  },
  visuallyHidden: {
    top: 20,
    width: 1,
    border: 0,
    height: 1,
    margin: -1,
    padding: 0,
    overflow: "hidden",
    position: "absolute",
    clip: "rect(0 0 0 0)",
  },
}));
