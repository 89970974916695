import React from "react";

import { Grid, Paper, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import UserCard from "./Card";

export default function UserGrid(props) {
  const classes = useStyles();

  const { users } = props;

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Users</Typography>
      <Grid container spacing={2}>
        {users.map((user, i) => (
          <Grid item key={i} xs={12} md={6}>
            <UserCard {...props} user={user} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
}));
