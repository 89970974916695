import React from "react";
import { Checkbox, FormControlLabel, Link } from "@material-ui/core/";

export const TermsCheckbox = (props) => {
  const { checked, onClick, accountType } = props;

  const linkHref =
    accountType === "Estimator"
      ? "/public/bidbox_contractor_terms_and_conditions.pdf"
      : "/public/bidbox_terms_of_service.pdf";

  const linkLabel =
    accountType === "Estimator"
      ? "Contractor Terms & Conditions"
      : "Terms of Service";

  return (
    <FormControlLabel
      control={
        <Checkbox
          name="terms"
          checked={checked}
          color="primary"
          onClick={onClick}
        />
      }
      label={
        <span>
          Click here to confirm you have read and agree to the{" "}
          <Link href={linkHref} target="_blank">
            {linkLabel}
          </Link>
          .
        </span>
      }
    />
  );
};
