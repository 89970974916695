import React from "react";

import { ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import ExitToAppSharpIcon from "@material-ui/icons/ExitToAppSharp";
import FolderOpenSharpIcon from "@material-ui/icons/FolderOpenSharp";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";
import TrendingFlatSharpIcon from "@material-ui/icons/TrendingFlatSharp";

export default function ContextMenu(props) {
  const {
    context,
    handleMain,
    handleClose,
    openFileForm,
    openLinkForm,
    removeFolder,
    openFolderForm,
    openMoveFolder,
    openFileDeleteForm,
    openLinkDeleteForm,
  } = props;

  function open() {
    handleMain(context.content);
    handleClose();
  }

  function edit() {
    if (context.content.type === "folder") {
      openFolderForm(context.content);
    } else if (context.content.type === "file") {
      openFileForm(context.content);
    } else if (context.content.type === "link") {
      openLinkForm(context.content);
    }
    handleClose();
  }

  function remove() {
    if (context.content.type === "folder") {
      removeFolder(context.content);
    } else if (context.content.type === "file") {
      openFileDeleteForm(context.content);
    } else if (context.content.type === "link") {
      openLinkDeleteForm(context.content);
    }
    handleClose();
  }

  function move() {
    openMoveFolder(context.content);
    handleClose();
  }

  return (
    <Menu
      keepMounted
      onClose={handleClose}
      style={{ width: 200 }}
      open={context.mouseY !== null}
      anchorReference="anchorPosition"
      anchorPosition={
        context.mouseY !== null && context.mouseX !== null
          ? { top: context.mouseY, left: context.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={open}>
        {context.content.type === "folder" ? (
          <React.Fragment>
            <ListItemIcon>
              <FolderOpenSharpIcon />
            </ListItemIcon>
            <Typography variant="inherit">Open</Typography>
          </React.Fragment>
        ) : context.content.type === "file" ? (
          <React.Fragment>
            <ListItemIcon>
              <GetAppSharpIcon />
            </ListItemIcon>
            <Typography variant="inherit">Download</Typography>
          </React.Fragment>
        ) : context.content.type === "link" ? (
          <React.Fragment>
            <ListItemIcon>
              <ExitToAppSharpIcon />
            </ListItemIcon>
            <Typography variant="inherit">Go to</Typography>
          </React.Fragment>
        ) : null}
      </MenuItem>

      <MenuItem onClick={edit}>
        <ListItemIcon>
          <EditSharpIcon />
        </ListItemIcon>
        <Typography variant="inherit">Edit</Typography>
      </MenuItem>
      <MenuItem onClick={remove}>
        <ListItemIcon>
          <DeleteSharpIcon />
        </ListItemIcon>
        <Typography variant="inherit">Remove</Typography>
      </MenuItem>
      <MenuItem onClick={move}>
        <ListItemIcon>
          <TrendingFlatSharpIcon />
        </ListItemIcon>
        <Typography variant="inherit">Move to</Typography>
      </MenuItem>
    </Menu>
  );
}
