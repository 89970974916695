import { apiCall } from "../../services/api";
import queryCreator from "../../services/queryCreator";
import { ADD_USER, LOAD_USER, LOAD_USERS, REMOVE_USER } from "../actionTypes";
import { checkFocus, setFocus } from "./focus";
import { setPage } from "./page";
import { addSnackbar } from "./snackbars";

export const resetUsers = () => ({
  type: LOAD_USERS,
  users: [],
});

export const loadUsers = (users) => ({
  type: LOAD_USERS,
  users,
});

export const addUser = (user) => ({
  type: ADD_USER,
  user,
});

export const loadUser = (user) => ({
  type: LOAD_USER,
  user,
});

export const removeUser = (id) => ({
  type: REMOVE_USER,
  id,
});

export const getUsers = (query) => {
  return async (dispatch) => {
    try {
      const res = await apiCall(
        "get",
        `/api/users?token=${queryCreator(query)}`
      );
      dispatch(loadUsers(res));
    } catch (err) {
      dispatch(addSnackbar(err, "error"));
    }
  };
};

export const getUser = (id, history) => async (dispatch) => {
  try {
    const res = await apiCall("get", `/api/users/${id}`);
    dispatch(setPage(res.username));
    dispatch(setFocus(res));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
    history.push("/notfound");
  }
};

export const postUser = (data, history) => async (dispatch) => {
  try {
    const res = await apiCall("post", "/api/signup", data);
    dispatch(addSnackbar(res, "success"));
    history.push("/login");
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putUser = (data) => async (dispatch) => {
  try {
    const res = await apiCall("put", `/api/users/${data.id}`, data);
    dispatch(loadUser(res));
    dispatch(checkFocus(res));
    dispatch(addSnackbar({ message: "User updated" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteUser = (history, id) => async (dispatch) => {
  try {
    const res = await apiCall("delete", `/api/users/${id}`);
    history.push("/users");
    dispatch(removeUser(res));
    dispatch(addSnackbar({ message: "User deleted" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const resendVerificationEmail = (id) => async (dispatch) => {
  try {
    const res = await apiCall(
      "post",
      `/api/users/${id}/resend-verification-email`
    );

    dispatch(addSnackbar({ message: res.message }, "success"));
  } catch (error) {
    dispatch(addSnackbar(error, "error"));
  }
};
