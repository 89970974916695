import moment from "moment";
import React, { Component } from "react";

import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core/";

import store from "../../store";
import { authUser } from "../../store/actions/auth";
import { generateLink } from "../../store/actions/passwordReset";
import PasswordReset from "./PasswordResetDialogue";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      resetEmail: "",
      remember: false,
      openPasswordReset: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password, remember } = this.state;
    let timeout = new Date();
    if (!remember) {
      timeout = moment(timeout).add(8, "h").valueOf();
    } else {
      timeout = moment(timeout).add(60, "d").valueOf();
    }
    store.dispatch(authUser({ email, password, timeout }, this.props.history));
  };

  handlePasswordReset = async (email) => {
    await store.dispatch(generateLink(email));
  };

  closePasswordReset = (e) => {
    e.preventDefault();
    this.setState({ openPasswordReset: false });
  };

  render() {
    const {
      email,
      password,
      remember,
      resetEmail,
      openPasswordReset,
    } = this.state;
    const { history } = this.props;
    return (
      <Container style={{ padding: 24 }} component={Paper} maxWidth="xs" square>
        <Typography text="primary" component="h1" variant="h5">
          BidBox Login
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <TextField
            required
            fullWidth
            autoFocus
            name="email"
            label="Email"
            value={email}
            margin="normal"
            variant="outlined"
            autoComplete="email"
            onChange={this.handleChange}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            name="password"
            type="password"
            label="Password"
            value={password}
            variant="outlined"
            onChange={this.handleChange}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={
              <Checkbox
                name="remember"
                color="primary"
                checked={remember}
                value={this.state.remember}
                onChange={this.handleChange}
              />
            }
            color="primary"
            label="Remember me"
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign In
          </Button>
        </form>
        <Grid container style={{ marginTop: 25 }}>
          <Grid item xs={6}>
            <Button
              size="small"
              style={{ width: "100%" }}
              onClick={() => this.setState({ openPasswordReset: true })}
            >
              Forgot password?
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="small"
              style={{ width: "100%" }}
              onClick={() => history.push("/signup")}
            >
              Click here to sign up!
            </Button>
          </Grid>
        </Grid>
        <PasswordReset
          email={resetEmail}
          open={openPasswordReset}
          handleChange={this.handleChange}
          handleClose={this.closePasswordReset}
          handleSubmit={this.handlePasswordReset}
        />
      </Container>
    );
  }
}
