import React from "react";

import { Typography } from "@material-ui/core/";

export default function NotFound() {
  return (
    <Typography variant="h6">
      Sorry couldn't find what you were looking for :(
    </Typography>
  );
}
