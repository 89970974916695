import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Drawer, Hidden, SwipeableDrawer } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import DrawerList from "./DrawerList";
import TopAppBar from "./TopAppBar";

class Wrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
    };
  }

  handleDrawerToggle = () => {
    this.setState({ drawer: !this.state.drawer });
  };

  render() {
    const { drawer } = this.state;
    const { classes, currentUser, history } = this.props;
    return (
      <div className={classes.root}>
        {currentUser.isAuthenticated && (
          <TopAppBar
            theme
            history={history}
            handleDrawerToggle={this.handleDrawerToggle}
          />
        )}
        <nav className={classes.drawer}>
          <Hidden smUp>
            <SwipeableDrawer
              open={drawer}
              anchor={"left"}
              variant="temporary"
              onOpen={this.handleDrawerToggle}
              onClick={this.handleDrawerToggle}
              onClose={this.handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              classes={{ paper: classes.drawerPaper }}
            >
              <DrawerList currentUser={currentUser} />
            </SwipeableDrawer>
          </Hidden>
          <Hidden xsDown>
            <Drawer
              open
              variant="permanent"
              classes={{ paper: classes.drawerPaper }}
            >
              <DrawerList currentUser={currentUser} />
            </Drawer>
          </Hidden>
        </nav>
      </div>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: 200,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    width: 200,
  },
}));

function StyledWrapper(props) {
  const classes = useStyles();
  return <Wrapper {...props} classes={classes} />;
}

function mapStateToProps(state) {
  return {
    page: state.page,
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, undefined)(withRouter(StyledWrapper));
