import React, { Component } from "react";
import { connect } from "react-redux";

import { addHistory } from "../store/actions/history";
import { setPage } from "../store/actions/page";
import { addSnackbar } from "../store/actions/snackbars";

export default function withAuth(ComponentToBeRendered, options) {
  class Authenticate extends Component {
    constructor(props) {
      super(props);
      this.state = { auth: false };
    }
    componentDidMount() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      const {
        isAuthenticated,
        accountType,
        history,
        addHistory,
        addSnackbar,
        setPage,
      } = this.props;
      addHistory(history.location.pathname);
      if (isAuthenticated === false) {
        addSnackbar(
          {
            status: 401,
            message: "Please Login First",
          },
          "error"
        );
        history.push("/login");
      } else if (options.admin && accountType !== "Admin") {
        addSnackbar(
          {
            status: 401,
            message: "Insufficient privileges",
          },
          "error"
        );
        history.push("/");
      } else {
        this.setState({ auth: true });
        if (options.title) {
          setPage(options.title);
        }
      }
    }

    render() {
      if (this.state.auth) {
        return <ComponentToBeRendered {...this.props} />;
      } else {
        return <div></div>;
      }
    }
  }

  function mapStateToProps(state) {
    return {
      id: state.currentUser.id,
      isAuthenticated: state.currentUser.isAuthenticated,
      accountType: state.currentUser.accountType,
    };
  }

  return connect(mapStateToProps, { addHistory, addSnackbar, setPage })(
    Authenticate
  );
}
