import React, { useEffect, useState } from "react";

import {
  AppBar,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";

import { checklist } from "../../settings/checklist";
import { SlideUp } from "../Common/SlideUp";
import { UserSelect } from "./UserSelect";

const defaultForm = {
  acousticReport: false,
  address: "",
  description: "",
  doorHardwareSchedule: false,
  doorSchedule: false,
  energyEfficiencyReport: false,
  finishesSchedule: false,
  id: undefined,
  name: "",
  number: "",
  sls: 0,
  status: "Open",
  timestamp: undefined,
  type: "Residential",
  uls: 0,
  windowSchedule: false,
  wlu: "Pa",
};

const windLoads = {
  Residential: {
    sls: 600,
    uls: 1200,
  },
  Commercial: {
    sls: 900,
    uls: 1800,
  },
};

export const ProjectForm = (props) => {
  const { accountType, open, handleClose, project, postProject, putProject } =
    props;

  const [form, setForm] = useState(defaultForm);

  useEffect(() => {
    setForm({
      user: accountType === "Admin" && !project ? "" : undefined,
      acousticReport: project.acousticReport || false,
      address: project.address || "",
      description: project.description || "",
      doorHardwareSchedule: project.doorHardwareSchedule || false,
      doorSchedule: project.doorSchedule || false,
      energyEfficiencyReport: project.energyEfficiencyReport || false,
      finishesSchedule: project.finishesSchedule || false,
      name: project.name || "",
      number: project.number || "",
      sls: project.sls || 0,
      status: project.status || "Open",
      type: project.type || "Residential",
      uls: project.uls || 0,
      windowSchedule: project.windowSchedule || false,
      wlu: project.wlu || "Pa",
    });
  }, [project, accountType]);

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if ((name === "sls" || name === "uls") && value >= 10001) return;
    setForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = { ...form };

    if (
      data.uls === 0 ||
      data.sls === 0 ||
      data.uls === "" ||
      data.sls === ""
    ) {
      data.wlu = "Pa";
      data.sls = windLoads[data.type].sls;
      data.uls = windLoads[data.type].uls;
    }

    if (!project) {
      postProject(data);
    } else {
      putProject(project._id, data);
    }

    handleClose(false);
    setForm(defaultForm);
  };

  return (
    <Dialog fullScreen open={open} TransitionComponent={SlideUp}>
      <AppBar style={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => handleClose(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" style={{ textTransform: "capitalize" }}>
            {project ? project.name : "new project"}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="name"
                label="Name"
                margin="normal"
                variant="outlined"
                value={form.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                name="number"
                margin="normal"
                variant="outlined"
                label="Reference Number"
                value={form.number}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                name="type"
                label="Type"
                margin="normal"
                variant="outlined"
                value={form.type}
                onChange={handleChange}
              >
                <MenuItem value="Residential">Residential</MenuItem>
                <MenuItem value="Commercial">Commercial</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="address"
                margin="normal"
                label="Address"
                variant="outlined"
                value={form.address}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label="Description"
                name="description"
                onChange={handleChange}
                value={form.description}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                name="status"
                label="Status"
                margin="normal"
                variant="outlined"
                value={form.status}
                onChange={handleChange}
              >
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Complete">Complete</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                fullWidth
                name="sls"
                label="SLS"
                type="number"
                margin="normal"
                variant="outlined"
                value={form.sls}
                onChange={handleChange}
                helperText={
                  form.sls === 0 || form.sls === ""
                    ? "If left at 0 standard values will be applied"
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                required
                fullWidth
                name="uls"
                label="ULS"
                type="number"
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                value={form.uls}
                helperText={
                  form.uls === 0 || form.uls === ""
                    ? "If left at 0 standard values will be applied"
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                fullWidth
                name="wlu"
                margin="normal"
                variant="outlined"
                value={form.wlu}
                label="Wind Load Units"
                onChange={handleChange}
              >
                <MenuItem value="Pa">Pa</MenuItem>
                <MenuItem value="kPa">kPa</MenuItem>
                <MenuItem value="m/s">m/s</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Document Cheklist</FormLabel>
                <FormGroup>
                  {checklist.map((check, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          name={check.value}
                          onChange={handleChange}
                          checked={form[check.value]}
                        />
                      }
                      label={check.label}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            {accountType === "Admin" && !project && (
              <Grid item xs={12} md={6}>
                <UserSelect value={form.user} handleChange={handleChange} />
              </Grid>
            )}
            <Grid item xs={12} md={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};
