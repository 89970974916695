import {
  SET_CURRENT_USER,
  SET_DEFAULT_STATE,
  SET_DEFAULT_USER,
  UPDATE_CURRENT_USER,
} from "../actionTypes.js";

const DEFAULT_STATE = {
  isAuthenticated: false,
  id: null,
  username: null,
  email: null,
  accountType: null,
};

export default function currenUser(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        isAuthenticated: !!Object.keys(action.user).length,
        id: action.user.id,
        username: action.user.username,
        email: action.user.email,
        accountType: action.user.accountType,
      };
    case UPDATE_CURRENT_USER:
      return {
        isAuthenticated: !!Object.keys(action.user).length,
        id: state.email,
        username: action.user.username,
        email: state.email,
        accountType: state.accountType,
      };
    case SET_DEFAULT_USER:
      return DEFAULT_STATE;
    case SET_DEFAULT_STATE:
      return DEFAULT_STATE;
    default:
      return state;
  }
}
