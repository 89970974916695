import { combineReducers } from "redux";

import currentUser from "./currentUser";
import { focus } from "./focus";
import history from "./history";
import loader from "./loader";
import page from "./page";
import { projects } from "./projects";
import snackbars from "./snackbars";
import { timeLogs } from "./timeLogs";
import { users } from "./users";

const rootReducer = combineReducers({
  page,
  history,
  currentUser,
  snackbars,
  loader,
  focus,
  users,
  projects,
  timeLogs,
});

export default rootReducer;
