import PropTypes from "prop-types";
import React from "react";
import MaskedInput from "react-text-mask";

export function abnMask(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      minLength={11}
      keepCharPositions
      placeholderChar={"\u2000"}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[1-9]/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        /\d/,
      ]}
    />
  );
}

abnMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
