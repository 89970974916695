import { ADD_HISTORY, SET_DEFAULT_STATE } from "../actionTypes";

export default function history(state = ["/"], action) {
  switch (action.type) {
    case ADD_HISTORY:
      return [action.page, ...state];
    default:
      return state;
    case SET_DEFAULT_STATE:
      return state;
  }
}
