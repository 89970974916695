import React, { Component } from "react";
import { connect } from "react-redux";

import { Hidden } from "@material-ui/core/";

import { getProjects } from "../../store/actions/projects";
import {
  deleteTimeLog,
  getTimeLogs,
  postTimeLog,
  putTimeLog,
} from "../../store/actions/timeLogs";
import { getUsers } from "../../store/actions/users";
import DeleteDialog from "../Common/DeleteDialog";
import TimeLogForm from "./Form";
import TimeLogGrid from "./Grid";
import TimeLogTable from "./Table";

class TimeLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "All",
      timeLog: false,
      openForm: false,
      openDelete: false,
    };
  }

  async componentDidMount() {
    const {
      getUsers,
      timestamp,
      currentUser,
      getTimeLogs,
      getProjects,
      focusTimeLogs,
      usersTimestamp,
      projectsTimestamp,
    } = this.props;
    const { accountType, id } = currentUser;
    const query = accountType === "Admin" ? {} : { estimator: id };
    if (!focusTimeLogs & !timestamp) {
      await getTimeLogs(query);
    }
    if (!projectsTimestamp) {
      const projectQuery = accountType === "Admin" ? {} : { estimator: id };
      await getProjects(projectQuery);
    }
    if (!usersTimestamp && accountType === "Admin") {
      await getUsers({});
    }
  }

  toggleForm = (timeLog) => {
    this.setState({
      timeLog,
      openForm: !this.state.openForm,
    });
  };

  toggleDeleteForm = (timeLog) => {
    this.setState({
      timeLog,
      openDelete: !this.state.openDelete,
    });
  };

  handleDelete = async () => {
    const { timeLog } = this.state;
    const { history, deleteTimeLog } = this.props;
    await deleteTimeLog(history, timeLog._id);
    this.setState({
      timeLog: false,
      openDelete: false,
    });
  };

  handleFilter = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      filter: value,
    });
  };

  filterTimeLogs = (timeLogs) => {
    let { filter } = this.state;
    if (filter === "All") {
      return timeLogs;
    } else if (filter === "Pending Invoice") {
      return timeLogs.filter((timeLog) => timeLog.invoice.length < 1);
    } else if (filter === "Pending Payment") {
      return timeLogs.filter((timeLog) => timeLog.purchase.length < 1);
    }
    return timeLogs;
  };

  render() {
    const { openForm, openDelete, timeLog, filter } = this.state;
    const { timeLogs, focusTimeLogs, projects, users } = this.props;

    const filteredTimeLogs = this.filterTimeLogs(focusTimeLogs || timeLogs);

    let filteredProjects = projects.filter(
      (project) => project.status === "Open"
    );

    let estimators = users.filter(
      (user) => user.accountType === "Estimator" || user.accountType === "Admin"
    );

    return (
      <React.Fragment>
        <Hidden mdUp>
          <TimeLogGrid
            {...this.props}
            filter={filter}
            openForm={this.toggleForm}
            timeLogs={filteredTimeLogs}
            handleFilter={this.handleFilter}
            openDeleteForm={this.toggleDeleteForm}
          />
        </Hidden>

        <Hidden smDown>
          <TimeLogTable
            {...this.props}
            filter={filter}
            openForm={this.toggleForm}
            timeLogs={filteredTimeLogs}
            handleFilter={this.handleFilter}
            openDeleteForm={this.toggleDeleteForm}
          />
        </Hidden>

        <TimeLogForm
          {...this.props}
          open={openForm}
          timeLog={timeLog}
          estimators={estimators}
          projects={filteredProjects}
          handleClose={this.toggleForm}
        />

        <DeleteDialog
          open={openDelete}
          name="Time Entry"
          handleDelete={this.handleDelete}
          handleClose={this.toggleDeleteForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.list,
    timeLogs: state.timeLogs.list,
    projects: state.projects.list,
    currentUser: state.currentUser,
    timestamp: state.timeLogs.timestamp,
    usersTimestamp: state.users.timestamp,
    projectsTimestamp: state.projects.timestamp,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  putTimeLog,
  getTimeLogs,
  postTimeLog,
  getProjects,
  deleteTimeLog,
})(TimeLogs);
