import React, { Component } from "react";

import {
  AppBar,
  Button,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";

import { accountTypes } from "../../settings/accountTypes";
import { adminEmailNotifications } from "../../settings/adminEmailNotifications";
import { aluminiumSuppliers } from "../../settings/aluminiumSuppliers";
import { emailNotifications } from "../../settings/emailNotifications";
import { estimatingSoftware } from "../../settings/estimatingSoftware";
import { states } from "../../settings/states";
import { abnMask } from "../Common/Masks";

const defaultState = {
  abn: "",
  email: "",
  state: "",
  phone: "",
  street: "",
  suburb: "",
  company: "",
  username: "",
  postcode: "",
  id: undefined,
  accountType: "",
  timestamp: false,
  otherSoftware: "",
  aluminiumSuppliers: [],
  estimatingSoftware: [],
  emailNotifications: ["News and updates"],
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    const { user, timestamp } = props;
    if (user._id !== state.id || timestamp !== state.timestamp) {
      return {
        abn: user.abn || "",
        email: user.email || "",
        state: user.state || "",
        phone: user.phone || "",
        id: user._id || undefined,
        street: user.street || "",
        suburb: user.suburb || "",
        company: user.company || "",
        timestamp: timestamp || false,
        username: user.username || "",
        postcode: user.postcode || "",
        accountType: user.accountType || "",
        otherSoftware: user.otherSoftware || "",
        emailNotifications: user.emailNotifications || [],
        aluminiumSuppliers: user.aluminiumSuppliers || [],
        estimatingSoftware: user.estimatingSoftware || [],
      };
    }
    return null;
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [name]: value,
    });
  };

  handleOther = (e) => {
    e.preventDefault();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { handleClose, putUser, setLoader } = this.props;
    handleClose(false);
    setLoader(true);
    putUser(this.state);
    this.setState(defaultState);
    setLoader(false);
  };

  openTerms = () => {
    this.setState({ openTerms: true });
  };

  closeTerms = (terms) => {
    this.setState({
      terms,
      openTerms: false,
    });
  };

  render() {
    const { user, currentUser, open, handleClose } = this.props;
    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => handleClose(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              <span style={{ textTransform: "capitalize" }}>
                {user.username}
              </span>
            </Typography>
          </Toolbar>
        </AppBar>
        <Container component="main" maxWidth="md">
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id="accountType">Account Type</InputLabel>
                  <Select
                    name="accountType"
                    label="Account Type"
                    onChange={this.handleChange}
                    value={this.state.accountType}
                  >
                    {currentUser.accountType === "Admin" && (
                      <MenuItem value="Admin">Admin</MenuItem>
                    )}
                    {accountTypes.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="username"
                  variant="outlined"
                  label="Contact Name"
                  value={this.state.username}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  name="email"
                  label="Email"
                  margin="normal"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="phone"
                  label="Phone"
                  margin="normal"
                  variant="outlined"
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id="emailNotifications">
                    Email Notifications
                  </InputLabel>
                  <Select
                    multiple
                    name="emailNotifications"
                    label="Email Notifications"
                    onChange={this.handleChange}
                    value={this.state.emailNotifications}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {emailNotifications.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        <Checkbox
                          checked={this.state.emailNotifications.includes(x)}
                        />
                        <ListItemText primary={x} />
                      </MenuItem>
                    ))}
                    {adminEmailNotifications.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        <Checkbox
                          checked={this.state.emailNotifications.includes(x)}
                        />
                        <ListItemText primary={x} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="company"
                  margin="normal"
                  variant="outlined"
                  label="Company Name"
                  value={this.state.company}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="abn"
                  label="ABN"
                  margin="normal"
                  variant="outlined"
                  value={this.state.abn}
                  onChange={this.handleChange}
                  InputProps={{
                    inputComponent: abnMask,
                    value: this.state.abn,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="street"
                  margin="normal"
                  variant="outlined"
                  label="Street Address"
                  value={this.state.street}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  name="suburb"
                  label="Suburb"
                  margin="normal"
                  variant="outlined"
                  value={this.state.suburb}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  name="postcode"
                  label="Postcode"
                  variant="outlined"
                  value={this.state.postcode}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id="state">State</InputLabel>
                  <Select
                    name="state"
                    label="State"
                    value={this.state.state}
                    onChange={this.handleChange}
                  >
                    {states.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {this.state.accountType !== "Installer" && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="aluminiumSuppliers">
                      Aluminium Suppliers
                    </InputLabel>
                    <Select
                      multiple
                      name="aluminiumSuppliers"
                      label="Aluminium Suppliers"
                      onChange={this.handleChange}
                      value={this.state.aluminiumSuppliers}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {aluminiumSuppliers.map((x, i) => (
                        <MenuItem key={i} value={x}>
                          <Checkbox
                            checked={this.state.aluminiumSuppliers.includes(x)}
                          />
                          <ListItemText primary={x} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {this.state.accountType !== "Installer" && (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="estimatingSoftware">
                      Estimating Software
                    </InputLabel>
                    <Select
                      multiple
                      name="estimatingSoftware"
                      label="Estimating Software"
                      onChange={this.handleChange}
                      value={this.state.estimatingSoftware}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {estimatingSoftware.map((x, i) => (
                        <MenuItem key={i} value={x}>
                          <Checkbox
                            checked={this.state.estimatingSoftware.includes(x)}
                          />
                          <ListItemText primary={x} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {this.state.accountType !== "Installer" && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    name="otherSoftware"
                    label="Other Software"
                    onChange={this.handleChange}
                    value={this.state.otherSoftware}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    );
  }
}
