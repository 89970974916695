import React, { Component } from "react";
import { connect } from "react-redux";

import { Hidden } from "@material-ui/core/";

import { setLoader } from "../../store/actions/loader";
import { deleteUser, getUsers, putUser } from "../../store/actions/users";
import DeleteDialog from "../Common/DeleteDialog";
import UserForm from "./Form";
import UserGrid from "./Grid";
import UserTable from "./Table";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: false,
      openForm: false,
      openDelete: false,
    };
  }

  async componentDidMount() {
    const { getUsers } = this.props;
    await getUsers({});
  }

  toggleForm = (user) => {
    this.setState({
      user,
      openForm: !this.state.openForm,
    });
  };

  toggleDeleteForm = (user) => {
    this.setState({
      user,
      openDelete: !this.state.openDelete,
    });
  };

  closeDeleteForm = () => {
    this.setState({
      user: false,
      openDelete: false,
    });
  };

  handleDelete = async () => {
    const { user } = this.state;
    const { history, deleteUser } = this.props;

    await deleteUser(history, user._id);
    this.setState({
      user: false,
      openDelete: false,
    });
  };

  render() {
    const { users } = this.props;
    const { openForm, openDelete, user } = this.state;

    users.sort((a, b) => a.username.localeCompare(b.username));
    return (
      <React.Fragment>
        <Hidden mdUp>
          <UserGrid
            {...this.props}
            openForm={this.toggleForm}
            openDeleteForm={this.toggleDeleteForm}
          />
        </Hidden>

        <Hidden smDown>
          <UserTable
            {...this.props}
            openForm={this.toggleForm}
            openDeleteForm={this.toggleDeleteForm}
          />
        </Hidden>

        <UserForm
          {...this.props}
          user={user}
          open={openForm}
          handleClose={this.toggleForm}
        />

        <DeleteDialog
          open={openDelete}
          name={user.username}
          handleDelete={this.handleDelete}
          handleClose={this.toggleDeleteForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.list,
    currentUser: state.currentUser,
    timestamp: state.users.timestamp,
  };
}

export default connect(mapStateToProps, {
  putUser,
  getUsers,
  setLoader,
  deleteUser,
})(Users);
