import { apiCall } from "../../services/api";
import { addToList, removeFromList, updateList } from "./lists";
import { addSnackbar } from "./snackbars";

export const postFile = (id, state, config) => async (dispatch) => {
  try {
    let data = new FormData();
    data.append("file", state.file);
    const res = await apiCall(
      "post",
      `/api/projects/${id}/files/${state.activeDirectory._id}`,
      data,
      config
    );
    dispatch(addToList(res, "files"));
    dispatch(addSnackbar({ message: "File uploaded" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putFile = (id, fileID, data) => async (dispatch) => {
  try {
    const res = await apiCall(
      "put",
      `/api/projects/${id}/files/${fileID}`,
      data
    );
    dispatch(updateList(res, "files"));
    dispatch(addSnackbar({ message: "File updated" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteFile = (id, fileID) => async (dispatch) => {
  try {
    const res = await apiCall("delete", `/api/projects/${id}/files/${fileID}`);
    dispatch(removeFromList(res, "files"));
    dispatch(addSnackbar({ message: "File removed" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};
