import moment from "moment";
import React from "react";

import { Card, CardContent, CardHeader } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";

import Actions from "../Common/Actions";

export default function TimeLogCard(props) {
  const { timeLog } = props;

  return (
    <Card square>
      <CardHeader
        title={timeLog.project.name}
        subheader={timeLog.estimator.username}
        action={<Actions {...props} model="TimeLogs" item={timeLog} />}
      />
      <CardContent>
        <Typography style={{ fontSize: 14 }} gutterBottom>
          Start: {moment(timeLog.start).format("DD/MM/YYYY hh:mma")}
        </Typography>
        <Typography style={{ fontSize: 14 }} gutterBottom>
          End: {moment(timeLog.end).format("DD/MM/YYYY hh:mma")}
        </Typography>
        <Typography style={{ marginBottom: 12 }} gutterBottom>
          Billable Hours: {timeLog.hours}
        </Typography>
        {timeLog.invoice.length > 0 && (
          <Typography style={{ marginBottom: 12 }} gutterBottom>
            Invoice #: {timeLog.invoice}
          </Typography>
        )}
        {timeLog.purchase.length > 0 && (
          <Typography style={{ marginBottom: 12 }} gutterBottom>
            Invoice #: {timeLog.purchase}
          </Typography>
        )}
        <Typography
          style={{ marginBottom: 12 }}
          gutterBottom
          color="textSecondary"
        >
          Notes: <br />
          {timeLog.notes}
        </Typography>
      </CardContent>
    </Card>
  );
}
