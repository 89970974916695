import React, { Component } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
} from "@material-ui/core/";
import CloseSharpIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultState = {
  ext: "",
  filename: "",
  id: undefined,
  timestamp: undefined,
};

export default class FileForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.file._id !== state.id || props.timestamp !== state.timestamp) {
      const { file, timestamp } = props;

      const splitFilename = file.filename ? file.filename.split(".") : null;
      const ext = splitFilename ? splitFilename[splitFilename.length - 1] : "";

      if (splitFilename) {
        splitFilename.pop();
      }
      const filename = splitFilename ? splitFilename.join("") : "";

      return {
        id: file._id || undefined,
        timestamp: timestamp || undefined,
        ext,
        filename,
      };
    }
    return null;
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, filename, ext } = this.state;
    const { putFile, handleClose, project } = this.props;

    const filenameExt = filename + "." + ext;
    await putFile(project._id, id, { filename: filenameExt });
    handleClose(false);
    this.setState(defaultState);
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth={"sm"}
        fullWidth={true}
        PaperComponent={Paper}
        PaperProps={{ square: true }}
        TransitionComponent={Transition}
        onClose={() => handleClose(false)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>Rename File</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseSharpIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  margin="normal"
                  name="filename"
                  label="Filename"
                  variant="outlined"
                  value={this.state.filename}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}
