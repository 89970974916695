import moment from "moment";
import React, { Component } from "react";

import MomentUtils from "@date-io/moment";
import {
  AppBar,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import store from "../../store";
import { addSnackbar } from "../../store/actions/snackbars";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let time = "12:00";
let date = moment().format("YYYY-MM-DD");
let DateAndTime = moment(date + " " + time);

const defaultState = {
  hours: 0,
  notes: "",
  project: "",
  invoice: "",
  purchase: "",
  id: undefined,
  estimator: "",
  end: DateAndTime,
  start: DateAndTime,
  timestamp: undefined,
};

export default class TimeLogForm extends Component {
  constructor(props) {
    super(props);
    const { currentUser, project } = props;
    const { accountType, id } = currentUser;
    if (accountType === "Estimator") {
      defaultState.estimator = id;
    }
    if (project) {
      defaultState.project = project._id;
    }
    this.state = defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.timeLog._id !== state.id) {
      const { timeLog, timestamp, project, currentUser } = props;
      const { accountType, id } = currentUser;
      return {
        hours: timeLog.hours || 0,
        notes: timeLog.notes || "",
        id: timeLog._id || undefined,
        invoice: timeLog.invoice || "",
        end: timeLog.end || DateAndTime,
        purchase: timeLog.purchase || "",
        timestamp: timestamp || undefined,
        start: timeLog.start || DateAndTime,
        project: project
          ? project._id
          : timeLog.project
          ? timeLog.project._id || timeLog.project
          : "",
        estimator:
          accountType === "Estimator"
            ? id
            : timeLog.estimator
            ? timeLog.estimator._id || timeLog.estimator
            : "",
      };
    }
    return null;
  }

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleDate = (x) => {
    const { start, end } = this.state;
    let date = moment(x).format("YYYY-MM-DD");
    let startTime = moment(start).format("HH:mm");
    let endTime = moment(end).format("HH:mm");

    this.setState({
      start: moment(date + " " + startTime),
      end: moment(date + " " + endTime),
    });
  };

  handleStart = (start) => {
    this.setState({
      start,
      hours: this.hours(this.state.end, start),
    });
  };

  handleEnd = (end) => {
    this.setState({
      end,
      hours: this.hours(end, this.state.start),
    });
  };

  hours = (a, b) => {
    const end = moment(a);
    const start = moment(b);
    return end.diff(start, "hours");
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { project, estimator } = this.state;
    const { postTimeLog, putTimeLog, handleClose } = this.props;
    if (project === "") {
      store.dispatch(
        addSnackbar({ message: "Please select a project" }, "error")
      );
    } else if (estimator === "") {
      store.dispatch(
        addSnackbar({ message: "Please select an estimator" }, "error")
      );
    } else if (!this.state.id) {
      postTimeLog(this.state);
      handleClose(false);
      this.setState(defaultState);
    } else {
      putTimeLog(this.state);
      handleClose(false);
      this.setState(defaultState);
    }
  };

  render() {
    const {
      open,
      timeLog,
      projects,
      estimators,
      handleClose,
      currentUser,
    } = this.props;
    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => handleClose(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {timeLog ? "Edit TimeLog" : "New TimeLog"}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    label="Date"
                    disableToolbar
                    margin="normal"
                    variant="dialogue"
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    value={this.state.start}
                    onChange={this.handleDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    fullWidth
                    format="hh:mma"
                    margin="normal"
                    label="Start Time"
                    inputVariant="outlined"
                    value={this.state.start}
                    onChange={this.handleStart}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardTimePicker
                    fullWidth
                    format="hh:mma"
                    margin="normal"
                    label="End Time"
                    value={this.state.end}
                    inputVariant="outlined"
                    onChange={this.handleEnd}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  required
                  fullWidth
                  name="hours"
                  type="number"
                  label="Hours"
                  margin="normal"
                  variant="outlined"
                  value={this.state.hours}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  required
                  fullWidth
                  name="project"
                  margin="normal"
                  label="Project"
                  variant="outlined"
                  value={this.state.project}
                  onChange={this.handleChange}
                >
                  {projects.map((project, i) => (
                    <MenuItem key={i} value={project._id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  select
                  required
                  fullWidth
                  margin="normal"
                  name="estimator"
                  label="Estimator"
                  variant="outlined"
                  value={this.state.estimator}
                  onChange={this.handleChange}
                  disabled={
                    currentUser.accountType === "Estimator" ? true : false
                  }
                >
                  {currentUser.accountType === "Estimator" ? (
                    <MenuItem value={currentUser.id}>
                      <span style={{ textTransform: "capitalize" }}>
                        {currentUser.username}
                      </span>
                    </MenuItem>
                  ) : (
                    estimators.map((estimator, i) => (
                      <MenuItem key={i} value={estimator._id}>
                        <span style={{ textTransform: "capitalize" }}>
                          {estimator.username}
                        </span>
                      </MenuItem>
                    ))
                  )}
                </TextField>
              </Grid>
              {currentUser.accountType === "Admin" && (
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    name="invoice"
                    margin="normal"
                    label="Invoice #"
                    variant="outlined"
                    value={this.state.invoice}
                    onChange={this.handleChange}
                  />
                </Grid>
              )}
              {currentUser.accountType === "Admin" && (
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    margin="normal"
                    name="purchase"
                    variant="outlined"
                    label="Purchase #"
                    value={this.state.purchase}
                    onChange={this.handleChange}
                  />
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  name="notes"
                  label="Notes"
                  margin="normal"
                  variant="outlined"
                  value={this.state.notes}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    );
  }
}
