import React, { Component } from "react";
import { connect } from "react-redux";

import { verifyAccount } from "../../store/actions/auth";
import { unsetFocus } from "../../store/actions/focus";
import { setLoader } from "../../store/actions/loader";
import {
  deleteUser,
  getUser,
  putUser,
  resendVerificationEmail,
} from "../../store/actions/users";
import DeleteDialog from "../Common/DeleteDialog";
import AccountVerification from "./AccountVerification";
import UserCard from "./Card";
import UserForm from "./Form";

class UserShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      openDelete: false,
      timestamp: undefined,
      openVerification: false,
    };
  }

  async componentDidMount() {
    const { history, match, getUser } = this.props;
    await getUser(match.params.id, history);
  }

  componentWillUnmount() {
    this.props.unsetFocus();
  }

  toggleForm = () => {
    this.setState({
      openForm: !this.state.openForm,
    });
  };

  toggleDeleteForm = () => {
    this.setState({
      openDelete: !this.state.openDelete,
    });
  };

  handleDelete = async () => {
    const { history, user, deleteUser } = this.props;
    await deleteUser(history, user._id);
  };

  toggleVerficationForm = () => {
    this.setState({
      openVerification: !this.state.openVerification,
    });
  };

  verifyAccount = async () => {
    const { verifyAccount, user } = this.props;
    this.setState({ openVerification: false });
    await verifyAccount(user._id);
  };

  render() {
    const { user } = this.props;
    const { openForm, openDelete, openVerification } = this.state;
    if (user) {
      return (
        <React.Fragment>
          <UserCard
            {...this.props}
            parent="show"
            openForm={this.toggleForm}
            openDeleteForm={this.toggleDeleteForm}
            toggleVerficationForm={this.toggleVerficationForm}
          />
          <UserForm
            {...this.props}
            open={openForm}
            handleClose={this.toggleForm}
          />

          <DeleteDialog
            open={openDelete}
            name={user.username}
            handleDelete={this.handleDelete}
            handleClose={this.toggleDeleteForm}
          />

          <AccountVerification
            id={user._id}
            name={user.username}
            open={openVerification}
            verifyAccount={this.verifyAccount}
            handleClose={this.closeVerificationForm}
          />
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    user: state.focus.item || false,
    timestamp: state.focus.timestamp,
  };
}

export default connect(mapStateToProps, {
  getUser,
  putUser,
  setLoader,
  unsetFocus,
  deleteUser,
  verifyAccount,
  resendVerificationEmail,
})(UserShow);
