import { apiCall } from "../../services/api";
import queryCreator from "../../services/queryCreator";
import {
  ADD_PROJECT,
  LOAD_PROJECT,
  LOAD_PROJECTS,
  REMOVE_PROJECT,
} from "../actionTypes";
import { checkFocus, setFocus } from "./focus";
import { setPage } from "./page";
import { addSnackbar } from "./snackbars";

export const loadProjects = (projects) => ({
  type: LOAD_PROJECTS,
  projects,
});

export const addProject = (project) => ({
  type: ADD_PROJECT,
  project,
});

export const loadProject = (project) => ({
  type: LOAD_PROJECT,
  project,
});

export const removeProject = (id) => ({
  type: REMOVE_PROJECT,
  id,
});

export const getProjects = (query) => {
  return async (dispatch) => {
    try {
      const res = await apiCall(
        "get",
        `/api/projects?token=${queryCreator(query)}`
      );
      dispatch(loadProjects(res));
    } catch (err) {
      dispatch(addSnackbar(err, "error"));
    }
  };
};

export const getProject = (id, history) => async (dispatch) => {
  try {
    const res = await apiCall("get", `/api/projects/${id}`);
    dispatch(setPage(res.name));
    dispatch(setFocus(res));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
    history.push("/notfound");
  }
};

export const postProject = (data) => async (dispatch) => {
  try {
    const res = await apiCall("post", "/api/projects/", data);
    dispatch(addProject(res));
    dispatch(addSnackbar({ message: "Project created" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putProject = (id, data) => async (dispatch) => {
  try {
    const res = await apiCall("put", `/api/projects/${id}`, data);
    dispatch(loadProject(res));
    dispatch(checkFocus(res));
    dispatch(addSnackbar({ message: "Project updated" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteProject = (history, id) => async (dispatch) => {
  try {
    const res = await apiCall("delete", `/api/projects/${id}`);
    history.push("/projects");
    dispatch(removeProject(res));
    dispatch(addSnackbar({ message: "Project removed" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};
