import { apiCall } from "../../services/api";
import queryCreator from "../../services/queryCreator";
import {
  ADD_TIMELOG,
  LOAD_TIMELOG,
  LOAD_TIMELOGS,
  REMOVE_TIMELOG,
} from "../actionTypes";
import { addToList, removeFromList, updateList } from "./lists";
import { addSnackbar } from "./snackbars";

export const loadTimeLogs = (timeLogs) => ({
  type: LOAD_TIMELOGS,
  timeLogs,
});

export const loadTimeLog = (timeLog) => ({
  type: LOAD_TIMELOG,
  timeLog,
});

export const addTimeLog = (timeLog) => ({
  type: ADD_TIMELOG,
  timeLog,
});

export const removeTimeLog = (id) => ({
  type: REMOVE_TIMELOG,
  id,
});

export const getTimeLogs = (query) => {
  return async (dispatch) => {
    try {
      const res = await apiCall(
        "get",
        `/api/timeLogs?token=${queryCreator(query)}`
      );
      dispatch(loadTimeLogs(res));
    } catch (err) {
      dispatch(addSnackbar(err, "error"));
    }
  };
};

export const postTimeLog = (data) => async (dispatch) => {
  try {
    const res = await apiCall("post", "/api/timeLogs", data);
    dispatch(addToList(res, "timeLogs"));
    dispatch(addTimeLog(res));
    dispatch(addSnackbar({ message: "Time Log created" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putTimeLog = (data) => async (dispatch) => {
  try {
    const res = await apiCall("put", `/api/timeLogs/${data.id}`, data);
    dispatch(updateList(res, "timeLogs"));
    dispatch(loadTimeLog(res));
    dispatch(addSnackbar({ message: "TimeLog updated" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteTimeLog = (history, id) => async (dispatch) => {
  try {
    const res = await apiCall("delete", `/api/timeLogs/${id}`);
    dispatch(removeFromList(res, "timeLogs"));
    dispatch(removeTimeLog(res));
    dispatch(addSnackbar({ message: "TimeLog deleted" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};
