import { ADD_TO_LIST, REMOVE_FROM_LIST, UPDATE_LIST } from "../actionTypes";

export const addToList = (item, model) => ({
  type: ADD_TO_LIST,
  item,
  model,
});

export const updateList = (item, model) => ({
  type: UPDATE_LIST,
  item,
  model,
});

export const removeFromList = (id, model) => ({
  type: REMOVE_FROM_LIST,
  id,
  model,
});
