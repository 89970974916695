import React, { Component } from "react";

import {
  AppBar,
  Button,
  Container,
  Dialog,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultState = {
  text: "",
  id: undefined,
  timestamp: undefined,
};

export default class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.comment._id !== state.id || props.timestamp !== state.timestamp) {
      const { comment, timestamp } = props;
      return {
        text: comment.text || "",
        id: comment._id || undefined,
        timestamp: timestamp || undefined,
      };
    }
    return null;
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { postComment, putComment, handleClose, project } = this.props;
    if (!this.state.id) {
      postComment(project._id, this.state);
    } else {
      putComment(project._id, this.state.id, this.state);
    }
    handleClose(false);
    this.setState(defaultState);
  };

  render() {
    const { open, handleClose, comment } = this.props;
    return (
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <AppBar style={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={() => handleClose(false)}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {comment ? "Edit Comment" : "New Comment"}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="text"
                  margin="normal"
                  label="Comment"
                  variant="outlined"
                  value={this.state.text}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dialog>
    );
  }
}
