import { apiCall } from "../../services/api";
import { setLoader } from "./loader";
import { addSnackbar } from "./snackbars";

export const generateLink = (email) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const res = await apiCall("get", `/api/password-reset/${email}`);
      dispatch(addSnackbar(res, "success"));
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(addSnackbar(err, "error"));
      dispatch(setLoader(false));
    }
  };
};

export const passwordReset = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch(setLoader(true));
      const res = await apiCall("post", `/api/password-reset/${data.id}`, data);
      dispatch(addSnackbar(res, "success"));
      history.push("/login");
      dispatch(setLoader(false));
    } catch (err) {
      dispatch(addSnackbar(err, "error"));
      history.push("/login");
      dispatch(setLoader(false));
    }
  };
};
