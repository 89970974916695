import React, { Component } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  TextField,
} from "@material-ui/core/";
import CloseSharpIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const defaultState = {
  url: "",
  id: undefined,
  description: "",
  timestamp: undefined,
};

export default class LinkForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.link._id !== state.id || props.timestamp !== state.timestamp) {
      const { link, timestamp } = props;
      return {
        url: link.url || "",
        id: link._id || undefined,
        timestamp: timestamp || undefined,
        description: link.description || "",
      };
    }
    return null;
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      putLink,
      project,
      postLink,
      handleClose,
      activeDirectory,
    } = this.props;
    let data = { ...this.state, dir: activeDirectory._id };
    if (!this.state.id) {
      await postLink(project._id, data);
    } else {
      await putLink(project._id, this.state.id, data);
    }
    handleClose(false);
    this.setState(defaultState);
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Dialog
        open={open}
        maxWidth={"sm"}
        fullWidth={true}
        PaperComponent={Paper}
        PaperProps={{ square: true }}
        TransitionComponent={Transition}
        onClose={() => handleClose(false)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>{this.state.id ? "Edit Link" : "New Link"}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseSharpIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="url"
                  type="url"
                  label="URL"
                  margin="normal"
                  variant="outlined"
                  value={this.state.url}
                  onChange={this.handleChange}
                  placeholder="e.g. https://www.google.com (http or https is required)"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="description"
                  label="Description"
                  onChange={this.handleChange}
                  value={this.state.description}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}
