import moment from "moment";
import React from "react";

import { Card, CardHeader, Typography } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";

import Actions from "../Common/Actions";

const useStyles = makeStyles({
  header: {
    padding: "5px 10px",
  },
  title: {
    fontSize: 14,
  },
  text: {
    whiteSpace: "pre-wrap",
  },
});

export default function CommentCard(props) {
  const classes = useStyles();

  const { comment, currentUser } = props;
  return (
    <Card variant="outlined" square>
      <CardHeader
        className={classes.header}
        title={
          <div>
            <Typography className={classes.title} color="textSecondary">
              <span style={{ textTransform: "capitalize" }}>
                {comment.createdBy
                  ? comment.createdBy.username
                  : "Deleted User"}
              </span>{" "}
              <span style={{ color: "#A4A3A1" }}>
                {moment(comment.createdAt).format("DD/MM/YYYY hh:mma")}
              </span>
            </Typography>
            <Typography component="p" gutterBottom className={classes.text}>
              {comment.text}
            </Typography>
          </div>
        }
        action={
          currentUser.accountType !== "Estimator" && (
            <Actions {...props} item={comment} />
          )
        }
      />
    </Card>
  );
}
