import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core/";

import { accountTypes } from "../../settings/accountTypes";
import { aluminiumSuppliers } from "../../settings/aluminiumSuppliers";
import { estimatingSoftware } from "../../settings/estimatingSoftware";
import { states } from "../../settings/states";
import { setLoader } from "../../store/actions/loader";
import { addSnackbar } from "../../store/actions/snackbars";
import { postUser } from "../../store/actions/users";
import { abnMask } from "../Common/Masks";
import { TermsCheckbox } from "./TermsCheckbox";

const defaultState = {
  abn: "",
  accountType: "",
  aluminiumSuppliers: [],
  company: "",
  email: "",
  emailNotifications: ["News and updates"],
  estimatingSoftware: [],
  openTerms: false,
  otherSoftware: "",
  password: "",
  passwordVerification: "",
  phone: "",
  postcode: "",
  state: "",
  street: "",
  suburb: "",
  terms: false,
  username: "",
};

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleOther = (e) => {
    e.preventDefault();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { history, postUser, addSnackbar } = this.props;
    const { password, passwordVerification } = this.state;
    if (password && password.length > 0 && password !== passwordVerification) {
      addSnackbar({ message: "Passwords do not match!" }, "error");
    } else {
      setLoader(true);
      await postUser(this.state, history);
      setLoader(false);
    }
  };

  render() {
    const { user, history } = this.props;
    const { terms } = this.state;
    return (
      <Container style={{ padding: 24 }} component={Paper} square maxWidth="md">
        <Typography text="primary" component="h1" variant="h5">
          BidBox Signup
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="accountType">Account Type</InputLabel>
                <Select
                  label="Account Type"
                  name="accountType"
                  onChange={this.handleChange}
                  required
                  value={this.state.accountType}
                >
                  {accountTypes.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                margin="normal"
                name="username"
                variant="outlined"
                label="Contact Name"
                value={this.state.username}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                type="email"
                name="email"
                label="Email"
                margin="normal"
                variant="outlined"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="phone"
                label="Phone"
                margin="normal"
                variant="outlined"
                value={this.state.phone}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                name="password"
                type="password"
                required={!user}
                label="Password"
                variant="outlined"
                value={this.state.password}
                onChange={this.handleChange}
                inputProps={{
                  pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*|.*[#$^+=!*()@%&]).{8,}",
                  title:
                    "Minimum 8 characters with at least one lowercase, one uppercase and one number or symbol",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                margin="normal"
                type="password"
                required={!user}
                variant="outlined"
                label="Verify Password"
                name="passwordVerification"
                onChange={this.handleChange}
                value={this.state.passwordVerification}
                inputProps={{
                  pattern: "(?=.*[a-z])(?=.*[A-Z])(?=.*|.*[#$^+=!*()@%&]).{8,}",
                  title:
                    "Minimum 8 characters with at least one lowercase, one uppercase and one number or symbol",
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="company"
                margin="normal"
                variant="outlined"
                label="Company Name"
                value={this.state.company}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="abn"
                label="ABN"
                margin="normal"
                variant="outlined"
                value={this.state.abn}
                onChange={this.handleChange}
                InputProps={{
                  inputComponent: abnMask,
                  value: this.state.abn,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="street"
                margin="normal"
                variant="outlined"
                label="Street Address"
                value={this.state.street}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                name="suburb"
                label="Suburb"
                margin="normal"
                variant="outlined"
                value={this.state.suburb}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                margin="normal"
                name="postcode"
                label="Postcode"
                variant="outlined"
                value={this.state.postcode}
                onChange={this.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel id="state">State</InputLabel>
                <Select
                  name="state"
                  label="State"
                  value={this.state.state}
                  onChange={this.handleChange}
                >
                  {states.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {this.state.accountType !== "Installer" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id="aluminiumSuppliers">
                    Aluminium Suppliers
                  </InputLabel>
                  <Select
                    multiple
                    name="aluminiumSuppliers"
                    label="Aluminium Suppliers"
                    onChange={this.handleChange}
                    value={this.state.aluminiumSuppliers}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {aluminiumSuppliers.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        <Checkbox
                          checked={this.state.aluminiumSuppliers.includes(x)}
                        />
                        <ListItemText primary={x} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {this.state.accountType !== "Installer" && (
              <Grid item xs={12} md={6}>
                <FormControl fullWidth margin="normal" variant="outlined">
                  <InputLabel id="estimatingSoftware">
                    Estimating Software
                  </InputLabel>
                  <Select
                    multiple
                    name="estimatingSoftware"
                    label="Estimating Software"
                    onChange={this.handleChange}
                    value={this.state.estimatingSoftware}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {estimatingSoftware.map((x, i) => (
                      <MenuItem key={i} value={x}>
                        <Checkbox
                          checked={this.state.estimatingSoftware.includes(x)}
                        />
                        <ListItemText primary={x} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {this.state.accountType !== "Installer" && (
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  name="otherSoftware"
                  label="Other Software"
                  onChange={this.handleChange}
                  value={this.state.otherSoftware}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TermsCheckbox
                accountType={this.state.accountType}
                checked={terms}
                onClick={this.handleChange}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!this.state.terms}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>

        <Button
          size="small"
          onClick={() => history.push("/login")}
          style={{ width: "100%", marginTop: 25 }}
        >
          Already have an account? Click here to Login
        </Button>
      </Container>
    );
  }
}

export default connect(undefined, {
  postUser,
  setLoader,
  addSnackbar,
})(SignupForm);
