import React, { Component } from "react";
import { connect } from "react-redux";

import { deleteFile, postFile, putFile } from "../../store/actions/files";
import { deleteLink, postLink, putLink } from "../../store/actions/links";
import { setLoader } from "../../store/actions/loader";
import DeleteDialog from "../Common/DeleteDialog";
import {
  deleteDirectory,
  generateDirectory,
  generatePath,
} from "./DirectoryFunctions";
import FileForm from "./FileForm";
import FolderForm from "./FolderForm";
import LinkForm from "./LinkForm";
import MoveFolderDialogue from "./MoveFolderDialogue";
import FileTable from "./Table";

class Directory extends Component {
  constructor(props) {
    super(props);
    const { timestamp } = props;
    this.state = {
      path: [],
      file: false,
      link: false,
      folder: false,
      content: false,
      deleteItem: false,
      openFileForm: false,
      openLinkForm: false,
      timestamp: timestamp,
      openFileDelete: false,
      openFolderForm: false,
      openLinkDelete: false,
      openMoveFolder: false,
      activeDirectory: { contents: [] },
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.timestamp !== state.timestamp) {
      const { activeDirectory } = state;
      const { project, timestamp } = props;

      const updatedDirectory = generateDirectory(activeDirectory, project);
      const path = generatePath(activeDirectory, project);

      return {
        path,
        timestamp: timestamp || undefined,
        activeDirectory: updatedDirectory,
      };
    }
    return null;
  }

  componentDidMount() {
    const { project } = this.props;
    this.setActiveDirectory(project.dir);
  }

  setActiveDirectory = (directory) => {
    const { project } = this.props;
    const activeDirectory = generateDirectory(directory, project);
    const path = generatePath(directory, project);
    this.setState({ activeDirectory, path });
  };

  toggleFolderForm = (folder) => {
    this.setState({ folder, openFolderForm: !this.state.openFolderForm });
  };

  toggleFileForm = (file) => {
    this.setState({ file, openFileForm: !this.state.openFileForm });
  };

  toggleLinkForm = (link) => {
    this.setState({ link, openLinkForm: !this.state.openLinkForm });
  };

  toggleFileDeleteForm = (file) => {
    this.setState({
      file,
      openFileDelete: !this.state.openFileDelete,
    });
  };

  toggleLinkDeleteForm = (link) => {
    this.setState({
      link,
      openLinkDelete: !this.state.openLinkDelete,
    });
  };

  toggleMoveFolder = (content) => {
    this.setState({
      content,
      openMoveFolder: !this.state.openMoveFolder,
    });
  };

  handleFileDelete = async () => {
    const { file } = this.state;
    const { project, deleteFile } = this.props;
    await deleteFile(project._id, file._id);
    this.setState({
      file: false,
      openFileDelete: false,
    });
  };

  handleLinkDelete = async () => {
    const { link } = this.state;
    const { project, deleteLink } = this.props;
    await deleteLink(project._id, link._id);
    this.setState({
      link: false,
      openLinkDelete: false,
    });
  };

  handleUpload = async (event) => {
    const { project, postFile, setLoader } = this.props;
    setLoader(true);
    const f = event.target.files[0];
    await this.setState({ file: f });

    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`${percentCompleted}%`);
      },
    };
    await postFile(project._id, this.state, config);
    await this.setState({ file: "" });
    setLoader(false);
  };

  handleFolderDeletion = async (selectedDir) => {
    const { project, addSnackbar, putProject } = this.props;

    const { dir, files, links } = project;

    const fileCheck = files.find((x) => x.dir === selectedDir._id);
    const linkCheck = links.find((x) => x.dir === selectedDir._id);
    const folderCheck = selectedDir.children.length > 0 ? true : false;
    if (fileCheck || linkCheck || folderCheck) {
      addSnackbar({ message: "Folder must be empty before removing" }, "error");
    } else {
      let rootDirectory = { ...dir };
      rootDirectory.children = deleteDirectory(rootDirectory, selectedDir._id);
      await putProject(project._id, { dir: rootDirectory });
    }
  };

  render() {
    const {
      file,
      link,
      path,
      folder,
      content,
      openFileForm,
      openLinkForm,
      openFileDelete,
      openFolderForm,
      openLinkDelete,
      openMoveFolder,
      activeDirectory,
    } = this.state;
    return (
      <React.Fragment>
        <FileTable
          {...this.props}
          file={file}
          path={path}
          handleUpload={this.handleUpload}
          activeDirectory={activeDirectory}
          openFileForm={this.toggleFileForm}
          openLinkForm={this.toggleLinkForm}
          openFolderForm={this.toggleFolderForm}
          openMoveFolder={this.toggleMoveFolder}
          removeFolder={this.handleFolderDeletion}
          setActiveDirectory={this.setActiveDirectory}
          openFileDeleteForm={this.toggleFileDeleteForm}
          openLinkDeleteForm={this.toggleLinkDeleteForm}
        />

        <FolderForm
          {...this.props}
          folder={folder}
          open={openFolderForm}
          activeDirectory={activeDirectory}
          handleClose={this.toggleFolderForm}
        />

        <FileForm
          {...this.props}
          file={file}
          open={openFileForm}
          handleClose={this.toggleFileForm}
        />

        <LinkForm
          {...this.props}
          link={link}
          open={openLinkForm}
          activeDirectory={activeDirectory}
          handleClose={this.toggleLinkForm}
        />

        <MoveFolderDialogue
          {...this.props}
          content={content}
          open={openMoveFolder}
          handleClose={this.toggleMoveFolder}
        />

        <DeleteDialog
          name={file.filename}
          open={openFileDelete}
          handleDelete={this.handleFileDelete}
          handleClose={this.toggleFileDeleteForm}
        />

        <DeleteDialog
          name={link.url}
          open={openLinkDelete}
          handleDelete={this.handleLinkDelete}
          handleClose={this.toggleLinkDeleteForm}
        />
      </React.Fragment>
    );
  }
}

export default connect(undefined, {
  putFile,
  putLink,
  postFile,
  postLink,
  setLoader,
  deleteFile,
  deleteLink,
})(Directory);
