import {
  CLOSE_SNACKBAR,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
} from "../actionTypes";

export const addSnackbar = (event, variant) => {
  let message =
    event && event.message ? event.message : "Oops! Something went wrong";
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      message,
      options: {
        variant,
        autoHideDuration: 2000,
      },
      key: new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key,
});
