import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

import { adminEmailNotifications } from "../../settings/adminEmailNotifications";
import { aluminiumSuppliers } from "../../settings/aluminiumSuppliers";
import { emailNotifications } from "../../settings/emailNotifications";
import { estimatingSoftware } from "../../settings/estimatingSoftware";
import { states } from "../../settings/states";
import { unsetFocus } from "../../store/actions/focus";
import {
  deleteSelf,
  deleteTeamViewer,
  getSelf,
  postTeamViewer,
  putSelf,
} from "../../store/actions/self";
import { addSnackbar } from "../../store/actions/snackbars";
import DeleteDialog from "../Common/DeleteDialog";
import { abnMask } from "../Common/Masks";

const defaultState = {
  abn: "",
  state: "",
  phone: "",
  street: "",
  suburb: "",
  company: "",
  username: "",
  password: "",
  postcode: "",
  id: undefined,
  otherSoftware: "",
  emailNotifications: [],
  aluminiumSuppliers: [],
  estimatingSoftware: [],
  passwordVerification: "",

  teamViewerPassword: "",
  teamViewerRemoteControlID: "",

  rdpPort: "",
  rdpHostname: "",
  rdpUsername: "",
  rdpPassword: "",

  openDelete: false,
  timestamp: undefined,
};

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  async componentDidMount() {
    const { history, getSelf } = this.props;
    await getSelf(history);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.user._id !== state.id) {
      const { user } = props;
      return {
        abn: user.abn || "",
        email: user.email || "",
        state: user.state || "",
        phone: user.phone || "",
        id: user._id || undefined,
        street: user.street || "",
        suburb: user.suburb || "",
        company: user.company || "",
        username: user.username || "",
        postcode: user.postcode || "",
        accountType: user.accountType || "",
        otherSoftware: user.otherSoftware || "",
        emailNotifications: user.emailNotifications || [],
        aluminiumSuppliers: user.aluminiumSuppliers || [],
        estimatingSoftware: user.estimatingSoftware || [],
      };
    }
    return null;
  }

  componentWillUnmount() {
    this.props.unsetFocus();
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let data = { ...this.state };
    data.password = undefined;
    const { putSelf } = this.props;
    await putSelf(data);
  };

  handleChangePassword = async (e) => {
    e.preventDefault();
    const { putSelf, addSnackbar } = this.props;
    const { password, passwordVerification, id } = this.state;
    if (password && password.length > 0 && password !== passwordVerification) {
      addSnackbar({ message: "Passwords do not match!" }, "error");
    } else {
      await putSelf({ id, password });
      this.setState({ password: "", passwordVerification: "" });
    }
  };

  openDeleteForm = () => {
    this.setState({
      openDelete: true,
    });
  };

  closeDeleteForm = () => {
    this.setState({
      openDelete: false,
    });
  };

  handleDelete = async () => {
    const { history, user, deleteSelf, logout } = this.props;
    await deleteSelf(user._id);
    logout(history);
  };

  addTeamViewer = (e) => {
    e.preventDefault();
    const { postTeamViewer } = this.props;
    let { teamViewerRemoteControlID, teamViewerPassword, id } = this.state;

    teamViewerRemoteControlID =
      "r" + teamViewerRemoteControlID.split(" ").join("");
    let data;
    if (teamViewerPassword.length > 0) {
      data = { teamViewerRemoteControlID, teamViewerPassword, id };
    } else {
      data = { teamViewerRemoteControlID, id };
    }
    postTeamViewer(data);
  };

  deleteTeamViewer = () => {
    const { id } = this.state;
    const { deleteTeamViewer } = this.props;

    deleteTeamViewer(id);
    this.setState({ teamViewerRemoteControlID: "", teamViewerPassword: "" });
  };

  addRDP = (e) => {
    e.preventDefault();

    const { putSelf } = this.props;
    const { id, rdpHostname, rdpPort, rdpUsername, rdpPassword } = this.state;

    let data = { id, rdpHostname, rdpPort, rdpUsername, rdpPassword };
    putSelf(data);
  };

  deleteRDP = () => {
    const { putSelf } = this.props;

    let data = {
      rdpPort: null,
      id: this.state.id,
      rdpHostname: null,
      rdpUsername: null,
      rdpPassword: null,
    };
    putSelf(data);
    this.setState({
      rdpPort: "",
      rdpHostname: "",
      rdpUsername: "",
      rdpPassword: "",
    });
  };

  render() {
    const { user } = this.props;
    const { openDelete } = this.state;
    return (
      <Container maxWidth="md">
        <Card square>
          <CardHeader title={`User Settings`} subheader={`${user.email}`} />
          <CardContent>
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    name="username"
                    variant="outlined"
                    label="Contact Name"
                    value={this.state.username}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="phone"
                    label="Phone"
                    margin="normal"
                    variant="outlined"
                    value={this.state.phone}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="company"
                    margin="normal"
                    variant="outlined"
                    label="Company Name"
                    value={this.state.company}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="emailNotifications">
                      Email Notifications
                    </InputLabel>
                    <Select
                      multiple
                      name="emailNotifications"
                      label="Email Notifications"
                      onChange={this.handleChange}
                      value={this.state.emailNotifications}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {emailNotifications.map((x, i) => (
                        <MenuItem key={i} value={x}>
                          <Checkbox
                            checked={this.state.emailNotifications.includes(x)}
                          />
                          <ListItemText primary={x} />
                        </MenuItem>
                      ))}
                      {user.accountType === "Admin" &&
                        adminEmailNotifications.map((x, i) => (
                          <MenuItem key={i} value={x}>
                            <Checkbox
                              checked={this.state.emailNotifications.includes(
                                x
                              )}
                            />
                            <ListItemText primary={x} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="abn"
                    label="ABN"
                    margin="normal"
                    variant="outlined"
                    value={this.state.abn}
                    onChange={this.handleChange}
                    InputProps={{
                      inputComponent: abnMask,
                      value: this.state.abn,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="street"
                    margin="normal"
                    variant="outlined"
                    label="Street Address"
                    value={this.state.street}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    name="suburb"
                    label="Suburb"
                    margin="normal"
                    variant="outlined"
                    value={this.state.suburb}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    name="postcode"
                    label="Postcode"
                    variant="outlined"
                    value={this.state.postcode}
                    onChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="normal" variant="outlined">
                    <InputLabel id="state">State</InputLabel>
                    <Select
                      name="state"
                      label="State"
                      value={this.state.state}
                      onChange={this.handleChange}
                    >
                      {states.map((x, i) => (
                        <MenuItem key={i} value={x}>
                          {x}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {this.state.accountType !== "Installer" && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel id="aluminiumSuppliers">
                        Aluminium Suppliers
                      </InputLabel>
                      <Select
                        multiple
                        name="aluminiumSuppliers"
                        label="Aluminium Suppliers"
                        onChange={this.handleChange}
                        value={this.state.aluminiumSuppliers}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {aluminiumSuppliers.map((x, i) => (
                          <MenuItem key={i} value={x}>
                            <Checkbox
                              checked={this.state.aluminiumSuppliers.includes(
                                x
                              )}
                            />
                            <ListItemText primary={x} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.state.accountType !== "Installer" && (
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth margin="normal" variant="outlined">
                      <InputLabel id="estimatingSoftware">
                        Estimating Software
                      </InputLabel>
                      <Select
                        multiple
                        name="estimatingSoftware"
                        label="Estimating Software"
                        onChange={this.handleChange}
                        value={this.state.estimatingSoftware}
                        renderValue={(selected) => selected.join(", ")}
                      >
                        {estimatingSoftware.map((x, i) => (
                          <MenuItem key={i} value={x}>
                            <Checkbox
                              checked={this.state.estimatingSoftware.includes(
                                x
                              )}
                            />
                            <ListItemText primary={x} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {this.state.accountType !== "Installer" && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      name="otherSoftware"
                      label="Other Software"
                      onChange={this.handleChange}
                      value={this.state.otherSoftware}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Save Changes
                  </Button>
                </Grid>
              </Grid>
            </form>
            {user.accountType !== "Estimator" ? (
              <div>
                {!user.teamViewerRemoteControlID ? (
                  <form onSubmit={this.addTeamViewer}>
                    <Typography
                      variant="h5"
                      text="primary"
                      component="h3"
                      style={{ marginTop: 50 }}
                    >
                      TeamViewer Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          margin="normal"
                          variant="outlined"
                          label="TeamViewer ID"
                          onChange={this.handleChange}
                          name="teamViewerRemoteControlID"
                          value={this.state.teamViewerRemoteControlID}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          type="password"
                          variant="outlined"
                          name="teamViewerPassword"
                          onChange={this.handleChange}
                          value={this.state.teamViewerPassword}
                          label="TeamViewer Device Password (Optional)"
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Save TeamViewer Details
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <div>
                    <Typography
                      variant="h5"
                      text="primary"
                      component="h3"
                      style={{ marginTop: 50 }}
                    >
                      TeamViewer Remote Control ID:{" "}
                      {user.teamViewerRemoteControlID}
                    </Typography>
                    <Grid style={{ paddingTop: 20 }} item xs={12} md={12}>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={() => this.deleteTeamViewer()}
                      >
                        Delete TeamViewer Details
                      </Button>
                    </Grid>
                  </div>
                )}
                {!user.rdpHostname ? (
                  <form onSubmit={this.addRDP}>
                    <Typography
                      variant="h5"
                      text="primary"
                      component="h3"
                      style={{ marginTop: 50 }}
                    >
                      RDP Details
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          margin="normal"
                          variant="outlined"
                          name="rdpHostname"
                          label="RDP Hostname"
                          onChange={this.handleChange}
                          value={this.state.rdpHostname}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          required
                          fullWidth
                          type="text"
                          name="rdpPort"
                          margin="normal"
                          label="RDP Port"
                          variant="outlined"
                          value={this.state.rdpPort}
                          onChange={this.handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          type="text"
                          margin="normal"
                          variant="outlined"
                          name="rdpUsername"
                          label="Username (Optional)"
                          onChange={this.handleChange}
                          value={this.state.rdpUsername}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          type="password"
                          variant="outlined"
                          name="rdpPassword"
                          label="Password (Optional)"
                          onChange={this.handleChange}
                          value={this.state.rdpPassword}
                        />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          fullWidth
                          type="submit"
                          color="primary"
                          variant="contained"
                        >
                          Save RDP Details
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <div>
                    <Typography
                      variant="h5"
                      text="primary"
                      component="h3"
                      style={{ marginTop: 50 }}
                    >
                      RDP: {user.rdpHostname}:{user.rdpPort}/{user.rdpUsername}
                    </Typography>
                    <Grid style={{ paddingTop: 20 }} item xs={12} md={12}>
                      <Button
                        fullWidth
                        color="secondary"
                        variant="contained"
                        onClick={() => this.deleteRDP()}
                      >
                        Delete RDP Details
                      </Button>
                    </Grid>
                  </div>
                )}
              </div>
            ) : null}
            <form onSubmit={this.handleChangePassword}>
              <Typography
                variant="h5"
                text="primary"
                component="h3"
                style={{ marginTop: 50 }}
              >
                Change Password
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    name="password"
                    type="password"
                    label="Password"
                    variant="outlined"
                    value={this.state.password}
                    onChange={this.handleChange}
                    inputProps={{
                      pattern:
                        "(?=.*[a-z])(?=.*[A-Z])(?=.*|.*[#$^+=!*()@%&]).{8,}",
                      title:
                        "Minimum 8 characters with at least one lowercase, one uppercase and one number or symbol",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    type="password"
                    variant="outlined"
                    label="Verify Password"
                    name="passwordVerification"
                    onChange={this.handleChange}
                    value={this.state.passwordVerification}
                    inputProps={{
                      pattern:
                        "(?=.*[a-z])(?=.*[A-Z])(?=.*|.*[#$^+=!*()@%&]).{8,}",
                      title:
                        "Minimum 8 characters with at least one lowercase, one uppercase and one number or symbol",
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Update Password
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Typography
              variant="h5"
              text="primary"
              component="h3"
              style={{ marginTop: 50 }}
            >
              Delete Account
            </Typography>
            <Grid style={{ paddingTop: 20 }} item xs={12} md={12}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => this.openDeleteForm()}
                style={{ backgroundColor: "#E25241", color: "#fff" }}
              >
                Delete Account
              </Button>
            </Grid>
          </CardContent>
        </Card>

        <DeleteDialog
          name="account"
          open={openDelete}
          handleDelete={this.handleDelete}
          handleClose={this.closeDeleteForm}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
    user: state.focus.item || false,
    timestamp: state.focus.timestamp,
  };
}

export default connect(mapStateToProps, {
  getSelf,
  putSelf,
  unsetFocus,
  deleteSelf,
  addSnackbar,
  postTeamViewer,
  deleteTeamViewer,
})(Settings);
