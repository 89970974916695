import React from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core/";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import PersonAddSharpIcon from "@material-ui/icons/PersonAddSharp";
import SettingsEthernetSharpIcon from "@material-ui/icons/SettingsEthernetSharp";

import { checklist } from "../../settings/checklist";
import Actions from "../Common/Actions";
import { show } from "../Common/helperFunctions";

export default function ProjectCard(props) {
  const { currentUser, project, toggleUserSearch, parent } = props;

  return (
    <Card square variant={parent !== "show" ? "outlined" : null}>
      <CardHeader
        onClick={
          parent !== "show"
            ? (e) => show(e, props, "projects", project._id)
            : null
        }
        title={project.name}
        style={parent !== "pointer" ? { cursor: "pointer" } : {}}
        subheader={
          <Typography
            color={project.status === "Open" ? "primary" : "error"}
            style={{
              fontSize: 14,
            }}
            gutterBottom
          >
            {project.status}
            {project.estimator && (
              <span>
                : Assigned to{" "}
                <span style={{ textTransform: "capitalize" }}>
                  {project.estimator.username}
                </span>
              </span>
            )}
          </Typography>
        }
        action={
          <React.Fragment>
            {parent === "show" && currentUser.accountType === "Admin" && (
              <IconButton
                className="ignore"
                aria-label="actions"
                onClick={toggleUserSearch}
              >
                <PersonAddSharpIcon />
              </IconButton>
            )}
            {currentUser.accountType !== "Estimator" && (
              <Actions {...props} model="Projects" item={project} />
            )}
          </React.Fragment>
        }
      />
      {parent === "show" && (
        <CardContent>
          <Typography style={{ fontSize: 14 }} gutterBottom>
            {project.createdBy.company} - {project.number}
          </Typography>
          <Typography
            gutterBottom
            color="textSecondary"
            style={{ marginBottom: 12 }}
          >
            {project.type} - {project.address}
          </Typography>
          <Typography
            gutterBottom
            color="textSecondary"
            style={{ marginBottom: 12 }}
          >
            {project.description}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography>Document checklist:</Typography>
              <List dense={true}>
                {checklist.map((check, i) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      {project[check.value] ? (
                        <CheckSharpIcon style={{ color: "#8bc34a" }} />
                      ) : (
                        <CloseSharpIcon style={{ color: "#bf360c" }} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={check.label} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Wind Loads:</Typography>
              <List dense={true}>
                <ListItem>
                  <ListItemText primary={`SLS: ${project.sls}${project.wlu}`} />
                </ListItem>
                <ListItem>
                  <ListItemText primary={`ULS: ${project.uls}${project.wlu}`} />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          {currentUser.accountType === ("Estimator" || "Admin") &&
            project.createdBy.teamViewerRemoteControlID && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                startIcon={<SettingsEthernetSharpIcon />}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#000" }}
                  href={`https://start.teamviewer.com/${
                    project.createdBy.teamViewerRemoteControlID.split("r")[1]
                  }`}
                >
                  Connect to TeamViewer
                </a>
              </Button>
            )}

          {currentUser.accountType === ("Estimator" || "Admin") &&
            project.createdBy.rdpHostname && (
              <Button
                size="small"
                color="primary"
                variant="outlined"
                startIcon={<SettingsEthernetSharpIcon />}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#000" }}
                  href={
                    process.env.NODE_ENV === "development"
                      ? `http://localhost:3030?${project.createdBy._id}`
                      : `https://rdp.bidbox.awes.com.au?${project.createdBy._id}`
                  }
                >
                  Connect to RDP
                </a>
              </Button>
            )}
        </CardContent>
      )}
    </Card>
  );
}
