import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
} from "@material-ui/core/";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountVerification(props) {
  const { open, verifyAccount, handleClose, name } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={Paper}
      PaperProps={{ square: true }}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ textTransform: "capitalize" }}>
        Verify {name}?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to verify{" "}
          <span style={{ textTransform: "capitalize" }}>{name}</span>?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <IconButton onClick={handleClose}>
          <CloseSharpIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            verifyAccount();
          }}
        >
          <CheckSharpIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
