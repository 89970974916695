import React from "react";

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AddSharpIcon from "@material-ui/icons/AddSharp";

import ProjectCard from "./Card";

export default function ProjectGrid(props) {
  const classes = useStyles();

  const { currentUser, projects, openForm, handleFilter, filter } = props;

  return (
    <Paper className={classes.root} square>
      <Typography variant="h6">Projects</Typography>
      <Grid container spacing={2}>
        {currentUser.accountType !== "Estimator" && (
          <Grid item xs={12} md={6}>
            <Button
              color="primary"
              variant="outlined"
              style={{ width: "100%" }}
              startIcon={<AddSharpIcon />}
              onClick={() => openForm(false)}
            >
              Add New Project
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id="filter">filter</InputLabel>
            <Select
              id="filter"
              value={filter}
              labelId="filter"
              onChange={handleFilter}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Complete">Complete</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {projects.map((project, i) => (
          <Grid item key={i} xs={12} md={6}>
            <ProjectCard {...props} project={project} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
}));
