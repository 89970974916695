import { apiCall } from "../../services/api";
import { updateCurrentUser } from "./auth";
import { setFocus } from "./focus";
import { addSnackbar } from "./snackbars";

export const getSelf = (history) => async (dispatch) => {
  try {
    const res = await apiCall("get", `/api/self`);
    dispatch(setFocus(res));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
    history.push("/notfound");
  }
};

export const putSelf = (data) => async (dispatch) => {
  try {
    const res = await apiCall("put", `/api/self`, data);
    dispatch(setFocus(res));
    dispatch(updateCurrentUser(res));
    dispatch(addSnackbar({ message: "Settings saved" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteSelf = (id) => async (dispatch) => {
  try {
    await apiCall("delete", `/api/self`);
    dispatch(addSnackbar({ message: "Account deleted" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const postTeamViewer = (data) => async (dispatch) => {
  try {
    const res = await apiCall("post", `/api/self/teamviewer`, data);
    dispatch(setFocus(res));
    dispatch(updateCurrentUser(res));
    dispatch(addSnackbar({ message: "Settings saved" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putTeamViewer = (data) => async (dispatch) => {
  try {
    const res = await apiCall("put", `/api/self/teamviewer`, data);
    dispatch(setFocus(res));
    dispatch(updateCurrentUser(res));
    dispatch(addSnackbar({ message: "Settings saved" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteTeamViewer = (id) => async (dispatch) => {
  try {
    const res = await apiCall("delete", `/api/self/teamviewer`);
    dispatch(setFocus(res));
    dispatch(updateCurrentUser(res));
    dispatch(
      addSnackbar({ message: "TeamViewer settings deleted" }, "success")
    );
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};
