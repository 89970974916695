export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE";

// pages
export const SET_PAGE = "SET_PAGE";
export const ADD_HISTORY = "ADD_HISTORY";

// snackbars
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

// loader
export const SET_LOADER = "SET_LOADER";

// current user
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const SET_DEFAULT_USER = "SET_DEFAULT_USER";

// focus
export const SET_FOCUS = "SET_FOCUS";
export const CHECK_FOCUS = "CHECK_FOCUS";
export const UNSET_FOCUS = "UNSET_FOCUS";

// users
export const LOAD_USERS = "LOAD_USERS";
export const LOAD_USER = "LOAD_USER";
export const ADD_USER = "ADD_USER";
export const REMOVE_USER = "REMOVE_USER";

// projects
export const LOAD_PROJECTS = "LOAD_PROJECTS";
export const LOAD_PROJECT = "LOAD_PROJECT";
export const ADD_PROJECT = "ADD_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";

// timelogs
export const LOAD_TIMELOGS = "LOAD_TIMELOGS";
export const LOAD_TIMELOG = "LOAD_TIMELOG";
export const ADD_TIMELOG = "ADD_TIMELOG";
export const REMOVE_TIMELOG = "REMOVE_TIMELOG";

// focus lists
export const ADD_TO_LIST = "ADD_TO_LIST";
export const UPDATE_LIST = "UPDATE_LIST";
export const REMOVE_FROM_LIST = "REMOVE_FROM_LIST";
