import React from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseSharpIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TrendingFlatSharpIcon from "@material-ui/icons/TrendingFlatSharp";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";

import {
  deleteDirectory,
  findDirectory,
  updateDirectory,
} from "./DirectoryFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MoveFolderDialogue(props) {
  const classes = useStyles();
  const { open, project, content, putFile, putLink, putProject, handleClose } =
    props;

  async function handleSubmit(dir) {
    if (content.type === "folder") {
      let rootDirectory = { ...project.dir };
      const foundDirectory = findDirectory(content, rootDirectory);
      rootDirectory.children = deleteDirectory(
        rootDirectory,
        foundDirectory._id
      );
      rootDirectory = updateDirectory(dir, rootDirectory, foundDirectory);
      await putProject(project._id, { dir: rootDirectory });
    } else if (content.type === "file") {
      await putFile(project._id, content._id, { dir });
    } else if (content.type === "link") {
      await putLink(project._id, content._id, { dir });
    }
    handleClose();
  }

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes._id}
      nodeId={nodes._id}
      label={
        <div>
          {nodes.label}
          <IconButton size="small" onClick={(e) => handleSubmit(nodes._id)}>
            <TrendingFlatSharpIcon />
          </IconButton>
        </div>
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </TreeItem>
  );

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
      onClose={handleClose}
      PaperComponent={Paper}
      PaperProps={{ square: true }}
      TransitionComponent={Transition}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle>Move to</DialogTitle>
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseSharpIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.content}>
        <TreeView
          defaultExpanded={[project.dir._id]}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {renderTree(project.dir)}
        </TreeView>
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles({
  content: {
    minHeight: 500,
  },
});
