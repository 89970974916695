import React from "react";

import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Slide,
  TextField,
} from "@material-ui/core/";
import CloseIcon from "@material-ui/icons/Close";
import PersonIcon from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserSearch(props) {
  const { open, handleSearch, handleClose, query, users, addEstimator } = props;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ textTransform: "capitalize" }}>
        Search for an estimator
      </DialogTitle>
      <DialogContent>
        <TextField
          required
          fullWidth
          autoFocus
          name="query"
          value={query}
          label="Search"
          margin="normal"
          variant="outlined"
          onChange={handleSearch}
        />
        <List>
          {users.map((user, i) => (
            <ListItem key={i} button onClick={() => addEstimator(user._id)}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${user.username} - ${user.email}`} />
              <ListItemSecondaryAction>
                <PersonAddIcon />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon style={{ color: "#000" }} />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
