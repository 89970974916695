import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import checkAuth from "../hocs/checkAuth";
import withAuth from "../hocs/withAuth";
import EmailVerification from "./Auth/EmailVerification";
import Login from "./Auth/Login";
import PasswordReset from "./Auth/PasswordReset";
import Settings from "./Auth/Settings";
import Signup from "./Auth/Signup";
import Home from "./Home";
import NotFound from "./NotFound";
import Projects from "./Projects";
import Project from "./Projects/Show.js";
import TimeLogs from "./TimeLogs";
import Users from "./Users";
import User from "./Users/Show.js";

const routes = [
  { path: "/", Component: withAuth(Home, {}) },
  { path: "/login", Component: checkAuth(Login, { title: "Login" }) },
  { path: "/signup", Component: checkAuth(Signup, { title: "Signup" }) },
  { path: "/verify-email/:id", Component: checkAuth(EmailVerification, {}) },
  {
    path: "/password-reset/:id",
    Component: checkAuth(PasswordReset, { title: "Password Reset" }),
  },
  {
    path: "/settings",
    Component: withAuth(Settings, { self: true, title: "Settings" }),
  },
  {
    path: "/users",
    Component: withAuth(Users, { admin: true, title: "Users" }),
  },
  { path: "/users/:id", Component: withAuth(User, { admin: true }) },
  { path: "/projects", Component: withAuth(Projects, { title: "Projects" }) },
  { path: "/projects/:id", Component: withAuth(Project, {}) },
  {
    path: "/timeLogs",
    Component: withAuth(TimeLogs, { title: "Time Tracking" }),
  },
];

function Main() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Switch>
        {routes.map(({ path, Component }) => (
          <Route
            exact
            key={path}
            path={path}
            render={(props) => <Component {...props} />}
          />
        ))}
        <Route path="/*" component={NotFound} />
      </Switch>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 56,
    minHeight: "calc(100vh - 56px)",
    padding: 25,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      minHeight: "100vh",
      marginLeft: 200,
    },
  },
}));

export default withRouter(Main);
