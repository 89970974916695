import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { MenuItem, TextField } from "@material-ui/core";

import { getUsers } from "../../store/actions/users";

export const UserSelect = ({ value = "", handleChange }) => {
  const dispatch = useDispatch();
  const { timestamp, list, loading } = useSelector(({ users }) => ({
    ...users,
    list: users.list.filter(
      (x) => x.accountType !== "Admin" && x.accountType !== "Estimator"
    ),
  }));

  useEffect(() => {
    if (!timestamp && !loading) {
      dispatch(getUsers({}));
    }
  }, [timestamp, loading, dispatch]);

  return (
    <TextField
      fullWidth
      id="user-select"
      label="User"
      margin="normal"
      name="user"
      onChange={handleChange}
      required
      select
      value={value}
      variant="outlined"
    >
      <MenuItem value="">Select a user</MenuItem>
      {list.map((user) => (
        <MenuItem
          key={user._id}
          value={user._id}
          style={{ textTransform: "capitalize" }}
        >
          {user.username} - {user.company}
        </MenuItem>
      ))}
    </TextField>
  );
};
