import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
} from "@material-ui/core/";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteDialog(props) {
  const { open, handleDelete, handleClose, name } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={Paper}
      PaperProps={{ square: true }}
      TransitionComponent={Transition}
    >
      <DialogTitle>Delete {name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {name}?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <IconButton onClick={() => handleClose(false)}>
          <CloseSharpIcon />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <DeleteSharpIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
