import { apiCall } from "../../services/api";
import { addToList, removeFromList, updateList } from "./lists";
import { addSnackbar } from "./snackbars";

export const postLink = (id, data) => async (dispatch) => {
  try {
    const res = await apiCall("post", `/api/projects/${id}/links/`, data);
    dispatch(addToList(res, "links"));
    dispatch(addSnackbar({ message: "Link created" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putLink = (id, linkID, data) => async (dispatch) => {
  try {
    const res = await apiCall(
      "put",
      `/api/projects/${id}/links/${linkID}`,
      data
    );
    dispatch(updateList(res, "links"));
    dispatch(addSnackbar({ message: "Link updated" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteLink = (id, linkID) => async (dispatch) => {
  try {
    const res = await apiCall("delete", `/api/projects/${id}/links/${linkID}`);
    dispatch(removeFromList(res, "links"));
    dispatch(addSnackbar({ message: "Link removed" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};
