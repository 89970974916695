import React, { Component } from "react";

import { TextField } from "@material-ui/core/";

export default class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = { text: "" };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleEnter = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      this.handleSubmit(e);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { postComment, project } = this.props;
    postComment(project._id, this.state);
    this.setState({ text: "" });
  };

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          required
          multiline
          fullWidth
          name="text"
          margin="none"
          variant="outlined"
          value={this.state.text}
          onChange={this.handleChange}
          onKeyPress={this.handleEnter}
          placeholder="Type a comment..."
        />
      </form>
    );
  }
}
