import React, { Component } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  TextField,
} from "@material-ui/core/";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

import { updateDirectory } from "./DirectoryFunctions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class FolderForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      id: undefined,
      timestamp: undefined,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.folder._id !== state.id || props.timestamp !== state.timestamp) {
      const { folder, timestamp } = props;
      return {
        label: folder.label || "",
        id: folder._id || undefined,
        timestamp: timestamp || undefined,
      };
    }
    return null;
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { id, label } = this.state;
    const { activeDirectory, project, putProject, handleClose } = this.props;

    const rootDirectory = { ...project.dir };
    const updatedDirectory = updateDirectory(
      activeDirectory._id,
      rootDirectory,
      {
        id,
        label,
        children: [],
      }
    );
    await putProject(project._id, { dir: updatedDirectory });
    this.setState({ label: "" });
    handleClose(false);
  };

  render() {
    const { open, handleClose } = this.props;

    return (
      <Dialog
        open={open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={handleClose}
        PaperComponent={Paper}
        PaperProps={{ square: true }}
        TransitionComponent={Transition}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle>
            {this.state.id ? "Rename Folder" : "New Folder"}
          </DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseSharpIcon />
          </IconButton>
        </div>
        <DialogContent>
          <form onSubmit={this.handleSubmit}>
            <TextField
              required
              fullWidth
              autoFocus
              name="label"
              label="Label"
              margin="normal"
              variant="outlined"
              value={this.state.label}
              onChange={this.handleChange}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={handleClose}>Close</Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
              >
                Create
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}
