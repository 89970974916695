import { SET_PAGE } from "../actionTypes";

export default function page(state = "bidbox", action) {
  switch (action.type) {
    case SET_PAGE:
      document.title = `${action.page} || bidbox`;
      return action.page;
    default:
      return state;
  }
}
