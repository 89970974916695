import React from "react";

import { IconButton } from "@material-ui/core";
import DeleteSharpIcon from "@material-ui/icons/DeleteSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";

export default function Actions(props) {
  const { item, openForm, openDeleteForm } = props;
  return (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="edit"
        className="ignore"
        onClick={() => openForm(item)}
      >
        <EditSharpIcon />
      </IconButton>

      <IconButton
        size="small"
        className="ignore"
        aria-label="delete"
        onClick={() => openDeleteForm(item)}
      >
        <DeleteSharpIcon />
      </IconButton>
    </React.Fragment>
  );
}
