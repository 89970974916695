export const generateDirectory = (activeDirectory, project) => {
  const { dir, files, links } = project;
  const updatedDirectory = findDirectory(activeDirectory, dir);

  const folderList = updatedDirectory.children.map((x) => {
    const filteredFiles = files.filter((y) => y.dir === x._id);
    const length = filteredFiles.reduce((acc, file) => {
      if (file.dir === x._id) {
        acc += file.length;
      }
      return acc;
    }, 0);

    const modified = filteredFiles.sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })[0];

    return {
      ...x,
      length,
      name: x.label,
      type: "folder",
      updatedAt: modified ? modified.updatedAt : undefined,
      updatedBy: modified ? modified.updatedBy : undefined,
    };
  });

  const fileList = files.reduce((filtered, file) => {
    if (file.dir === updatedDirectory._id) {
      const x = {
        ...file,
        type: "file",
        _id: file._id,
        name: file.filename,
      };
      filtered.push(x);
    }
    return filtered;
  }, []);

  const linkList = links.reduce((filtered, link) => {
    if (link.dir === updatedDirectory._id) {
      const x = {
        ...link,
        type: "link",
        _id: link._id,
        name: link.description,
      };
      filtered.push(x);
    }
    return filtered;
  }, []);

  return {
    ...updatedDirectory,
    contents: [...folderList, ...fileList, ...linkList],
  };
};

export function findDirectory(activeDirectory, parentDirectory) {
  let foundDirectory;
  if (parentDirectory._id === activeDirectory._id) {
    foundDirectory = { ...parentDirectory };
  } else if (parentDirectory.children.length > 0) {
    for (const child of parentDirectory.children) {
      let foundSubDirectory = findDirectory(activeDirectory, child);
      if (foundSubDirectory) {
        foundDirectory = foundSubDirectory;
        break;
      }
    }
  }
  return foundDirectory;
}

export const generatePath = (activeDirectory, project) => {
  let path = searchDirectory(project.dir, activeDirectory, []).reverse();
  path.unshift({
    _id: project.dir._id,
    label: "Root Folder",
    children: [...project.dir.children],
  });
  return path;
};

function searchDirectory(parentDirectory, activeDirectory, acc) {
  const num = acc.length;
  for (const child of parentDirectory.children) {
    if (child._id === activeDirectory._id) {
      acc.push({ ...child });
      break;
    } else if (child.children.length > 0) {
      acc = searchDirectory(child, activeDirectory, acc);
      if (acc.length > num) {
        acc.push({ ...child });
        break;
      }
    }
  }
  return acc;
}

export function updateDirectory(activeDirectory, directory, x) {
  if (!x.id && activeDirectory === directory._id) {
    directory.children.push(x);
  } else if (x.id && x.id === directory._id) {
    directory.label = x.label;
  } else if (directory.children.length > 0) {
    for (const child of directory.children) {
      updateDirectory(activeDirectory, child, x);
    }
  }
  return directory;
}

export function deleteDirectory(directory, id) {
  const filtered = directory.children.filter((dir) => {
    if (dir.children.length > 0) {
      dir.children = deleteDirectory(dir, id);
    }
    if (dir._id !== id) {
      return true;
    } else {
      return false;
    }
  });
  return filtered;
}
