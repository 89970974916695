import jwt from "jsonwebtoken";
import { SnackbarProvider } from "notistack";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { CssBaseline } from "@material-ui/core/";
import { ThemeProvider } from "@material-ui/core/styles";

import Main from "./components/";
import Loader from "./components/Feedback/Loader";
import Notifier from "./components/Feedback/Notifier";
import Navigation from "./components/Navigation";
import store from "./store";
import {
  setAuthorizationToken,
  setCurrentUser,
  setDefaultUser,
} from "./store/actions/auth";
import { awesTheme } from "./themes";

if (localStorage.jwtToken) {
  try {
    const now = new Date().getTime();
    let decoded = jwt.decode(localStorage.jwtToken);

    if (now > decoded.timeout) {
      setAuthorizationToken(false);
      store.dispatch(setDefaultUser({}));
    } else {
      setAuthorizationToken(localStorage.jwtToken);
      store.dispatch(setCurrentUser(decoded));
    }
  } catch (e) {
    store.dispatch(setDefaultUser({}));
  }
}

export default function App() {
  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={awesTheme}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <CssBaseline />
            <Loader />
            <Notifier />
            <Navigation />
            <Main />
          </SnackbarProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}
