import {
  ADD_PROJECT,
  LOAD_PROJECT,
  LOAD_PROJECTS,
  REMOVE_PROJECT,
  SET_DEFAULT_STATE,
} from "../actionTypes";

const DEFAULT_STATE = {
  list: [],
  timestamp: false,
};

export const projects = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_PROJECTS:
      return {
        list: [...action.projects],
        timestamp: new Date(),
      };
    case ADD_PROJECT:
      return {
        list: [...state.list, action.project],
        timestamp: state.timestamp,
      };
    case LOAD_PROJECT:
      if (state.timestamp) {
        return {
          list: state.list.map((project) =>
            project._id === action.project._id ? action.project : project
          ),
          timestamp: state.timestamp,
        };
      } else {
        return state;
      }
    case REMOVE_PROJECT:
      return {
        list: state.list.filter((project) => project._id !== action.id),
        timestamp: state.timestamp,
      };
    case SET_DEFAULT_STATE:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
