import { apiCall, setTokenHeader } from "../../services/api";
import store from "../";
import {
  SET_CURRENT_USER,
  SET_DEFAULT_STATE,
  SET_DEFAULT_USER,
  UPDATE_CURRENT_USER,
} from "../actionTypes";
import { checkFocus } from "./focus";
import { setLoader } from "./loader";
import { addSnackbar } from "./snackbars";
import { loadUser } from "./users";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

export function updateCurrentUser(user) {
  return {
    type: UPDATE_CURRENT_USER,
    user,
  };
}

export function setDefaultUser() {
  return {
    type: SET_DEFAULT_USER,
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function logout(history) {
  return (dispatch) => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch({ type: SET_DEFAULT_STATE });
    if (history) {
      history.push("/login");
    } else {
      window.location.replace("/login");
    }
  };
}

export function verifyEmail(id, history) {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch(setLoader(true));
        let res = await apiCall("get", `/api/verify-email/${id}`);
        history.push("/login");
        dispatch(addSnackbar(res, "success"));
        dispatch(setLoader(false));
      } catch (err) {
        dispatch(addSnackbar(err, "error"));
        history.push("/login");
        dispatch(setLoader(false));
      }
    });
  };
}

export function verifyAccount(id) {
  return (dispatch) => {
    return new Promise(async () => {
      try {
        dispatch(setLoader(true));
        let res = await apiCall("get", `/api/verify-account/${id}`);
        dispatch(loadUser(res));
        dispatch(checkFocus(res));
        dispatch(
          addSnackbar({ message: "Account verified. User emailed." }, "success")
        );
        dispatch(setLoader(false));
      } catch (err) {
        dispatch(addSnackbar(err, "error"));
        dispatch(setLoader(false));
      }
    });
  };
}

export function authUser(data, history) {
  return (dispatch) => {
    return new Promise(async () => {
      try {
        dispatch(setLoader(true));
        const { token, ...user } = await apiCall("post", `/api/`, data);
        const state = store.getState();
        localStorage.setItem("jwtToken", token);
        setAuthorizationToken(token);
        dispatch(setCurrentUser(user));
        history.push(state.history[0]);
        dispatch(
          addSnackbar({ message: `Welcome back ${user.username}` }, "success")
        );
        dispatch(setLoader(false));
      } catch (err) {
        dispatch(addSnackbar(err, "error"));
        dispatch(setLoader(false));
      }
    });
  };
}
