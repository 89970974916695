import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AddSharpIcon from "@material-ui/icons/AddSharp";

import Actions from "../Common/Actions";

export default function TimeLogTable(props) {
  const classes = useStyles();
  const { currentUser, timeLogs, openForm, handleFilter, filter } = props;
  return (
    <TableContainer component={Paper} square>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          Time Logs
        </Typography>
        <FormControl style={{ width: "200px" }}>
          <InputLabel id="filter">filter</InputLabel>
          <Select labelId="filter" value={filter} onChange={handleFilter}>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Pending Invoice">Pending Invoice</MenuItem>
            <MenuItem value="Pending Payment">Pending Payment</MenuItem>
          </Select>
        </FormControl>
        {(currentUser.accountType === "Admin" ||
          currentUser.accountType === "Estimator") && (
          <Tooltip title="Add TimeLog">
            <IconButton
              onClick={() => openForm(false)}
              aria-label="add TimeLog"
            >
              <AddSharpIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Project</strong>
            </TableCell>
            <TableCell>
              <strong>Estimator</strong>
            </TableCell>
            <TableCell>
              <strong>Start</strong>
            </TableCell>
            <TableCell>
              <strong>End</strong>
            </TableCell>
            <TableCell>
              <strong>Hours</strong>
            </TableCell>
            <TableCell>
              <strong>Notes</strong>
            </TableCell>
            <TableCell>
              <strong>Invoice #</strong>
            </TableCell>
            <TableCell>
              <strong>Purchase #</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeLogs.map((timeLog, i) => (
            <TableRow key={i} className={classes.tableRow} hover>
              <TableCell
                component="th"
                scope="row"
                style={{ textTransform: "capitalize" }}
              >
                <Link to={`/projects/${timeLog.project._id}`}>
                  {timeLog.project.name}
                </Link>
              </TableCell>
              <TableCell>{timeLog.estimator.username}</TableCell>
              <TableCell>
                {moment(timeLog.start).format("DD/MM/YYYY hh:mma")}
              </TableCell>
              <TableCell>
                {moment(timeLog.end).format("DD/MM/YYYY hh:mma")}
              </TableCell>
              <TableCell>{timeLog.hours}</TableCell>
              <TableCell>{timeLog.notes}</TableCell>
              <TableCell>{timeLog.invoice}</TableCell>
              <TableCell>{timeLog.purchase}</TableCell>
              <TableCell align="center">
                {(currentUser.accountType === "Estimator" ||
                  currentUser.accountType === "Admin") && (
                  <Actions {...props} model="TimeLogs" item={timeLog} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
}));
