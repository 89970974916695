import { SET_FOCUS, UNSET_FOCUS } from "../actionTypes";

export const setFocus = (x) => ({
  type: SET_FOCUS,
  x,
});

export const checkFocus = (x) => ({
  type: SET_FOCUS,
  x,
});

export const unsetFocus = () => ({
  type: UNSET_FOCUS,
});
