import React from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import CheckSharpIcon from "@material-ui/icons/CheckSharp";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";

import Actions from "../Common/Actions";
import { show } from "../Common/helperFunctions";

export default function UserTable(props) {
  const classes = useStyles();
  const { users } = props;
  return (
    <TableContainer component={Paper} square>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.title} variant="h6">
          Users
        </Typography>
      </Toolbar>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>Username</strong>
            </TableCell>
            <TableCell>
              <strong>Email</strong>
            </TableCell>
            <TableCell>
              <strong>Company</strong>
            </TableCell>
            <TableCell>
              <strong>Account Type</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Email Verification</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Account Verification</strong>
            </TableCell>
            <TableCell align="center">
              <strong>Actions</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, i) => (
            <TableRow
              hover
              key={i}
              className={classes.tableRow}
              onClick={(e) => show(e, props, "users", user._id)}
            >
              <TableCell
                scope="row"
                component="th"
                style={{ textTransform: "capitalize" }}
              >
                {user.username}
              </TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.company}</TableCell>
              <TableCell>{user.accountType}</TableCell>
              <TableCell align="center">
                {user.emailVerification ? (
                  <CheckSharpIcon />
                ) : (
                  <CloseSharpIcon />
                )}
              </TableCell>
              <TableCell align="center">
                {user.accountVerification ? (
                  <CheckSharpIcon />
                ) : (
                  <CloseSharpIcon />
                )}
              </TableCell>
              <TableCell align="center">
                <Actions {...props} model="Users" item={user} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: "1 1 100%",
  },
  tableRow: {
    cursor: "Pointer",
  },
}));
