import React from "react";

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core/";

import Actions from "../Common/Actions";
import { show } from "../Common/helperFunctions";

export default function UserCard(props) {
  const { user, parent, toggleVerficationForm, resendVerificationEmail } =
    props;

  return (
    <Card square variant={parent !== "show" ? "outlined" : null}>
      <CardHeader
        subheader={`${user.email}`}
        onClick={(e) => show(e, props, "users", user._id)}
        style={parent !== "pointer" ? { cursor: "pointer" } : {}}
        action={<Actions {...props} model="Users" item={user} />}
        title={`${user.username[0].toUpperCase()}${user.username.slice(1)}`}
      />
      {parent === "show" && (
        <CardContent>
          <Typography style={{ fontSize: 14 }} gutterBottom>
            {user.code}
          </Typography>
          <Typography style={{ marginBottom: 12 }} color="textSecondary">
            {user.street},<br />
            {user.suburb}, {user.state}, {user.postcode}
          </Typography>
          <Typography variant="body2" component="p">
            <strong>Company:</strong> {user.company}
            <br />
            <strong>Account Type:</strong> {user.accountType}
            <br />
            <strong>phone:</strong> {user.phone}
            <br />
          </Typography>
          {user.accountType !== "Installer" && (
            <div>
              <br />
              <strong>Aluminium Suppliers:</strong>
              <List dense>
                {user.aluminiumSuppliers.map((x, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={x} />
                  </ListItem>
                ))}
              </List>
              <strong>Estimating Software:</strong>
              <List dense>
                {user.estimatingSoftware.map((x, i) => (
                  <ListItem key={i}>
                    <ListItemText primary={x} />
                  </ListItem>
                ))}
                {user.otherSoftware && user.otherSoftware.length > 0 && (
                  <ListItem>
                    <ListItemText primary={user.otherSoftware} />
                  </ListItem>
                )}
              </List>
            </div>
          )}
          <Grid container spacing={1}>
            {!user.accountVerification && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => toggleVerficationForm()}
                >
                  Verify Account
                </Button>
              </Grid>
            )}
            {!user.emailVerification && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => resendVerificationEmail(user._id)}
                >
                  Resend Verification Email
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}
