import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CommentCard from "./Card";
import InputForm from "./InputForm";

export default function CommendGridWrapper(props) {
  const classes = useStyles();
  const { comments } = props;

  return (
    <React.Fragment>
      <Hidden lgUp>
        <Accordion square>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              Comments{" "}
              <Badge badgeContent={comments.length} color="primary">
                <ChatBubbleIcon />
              </Badge>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CommentGrid {...props} />
          </AccordionDetails>
        </Accordion>
      </Hidden>

      <Hidden mdDown>
        <Paper className={classes.root} square>
          <Typography variant="h6" gutterBottom>
            Comments
          </Typography>

          <CommentGrid {...props} />
        </Paper>
      </Hidden>
    </React.Fragment>
  );
}

function CommentGrid(props) {
  const { comments } = props;

  return (
    <Grid container spacing={2}>
      {comments.map((comment, i) => (
        <Grid item key={i} xs={12}>
          <CommentCard {...props} comment={comment} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <InputForm {...props} />
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
  },
}));
