import {
  ADD_TO_LIST,
  CHECK_FOCUS,
  REMOVE_FROM_LIST,
  SET_DEFAULT_STATE,
  SET_FOCUS,
  UNSET_FOCUS,
  UPDATE_LIST,
} from "../actionTypes";

const DEFAULT_STATE = false;

export const focus = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case SET_FOCUS:
      return {
        item: action.x,
        timestamp: new Date(),
      };
    case CHECK_FOCUS:
      if (action.x._id === state.item._id) {
        return {
          item: action.x,
          timestamp: new Date(),
        };
      } else {
        return state;
      }
    case UNSET_FOCUS:
      return false;
    case ADD_TO_LIST:
      if (state.item && state.item[action.model]) {
        return {
          item: {
            ...state.item,
            [action.model]: [...state.item[action.model], action.item],
          },
          timestamp: new Date(),
        };
      } else {
        return state;
      }
    case UPDATE_LIST:
      if (state.item && state.item[action.model]) {
        return {
          item: {
            ...state.item,
            [action.model]: state.item[action.model].map((item) =>
              item._id === action.item._id ? action.item : item
            ),
          },
          timestamp: new Date(),
        };
      } else {
        return state;
      }
    case REMOVE_FROM_LIST:
      if (state.item && state.item[action.model]) {
        return {
          item: {
            ...state.item,
            [action.model]: state.item[action.model].filter(
              (item) => item._id !== action.id
            ),
          },
          timestamp: new Date(),
        };
      } else {
        return state;
      }
    case SET_DEFAULT_STATE:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
