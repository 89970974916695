import React, { Component } from "react";
import { connect } from "react-redux";

import {
  deleteComment,
  postComment,
  putComment,
} from "../../store/actions/comments";
import DeleteDialog from "../Common/DeleteDialog";
import CommentForm from "./Form";
import CommentGrid from "./Grid";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: false,
      deleteForm: false,
      commentForm: false,
    };
  }

  toggleCommentForm = (comment) => {
    this.setState({
      comment,
      commentForm: !this.state.commentForm,
    });
  };

  toggleDeleteForm = (comment) => {
    this.setState({
      comment,
      deleteForm: !this.state.deleteForm,
    });
  };

  handleDelete = async () => {
    const { comment } = this.state;
    const { deleteComment, project } = this.props;

    await deleteComment(project._id, comment._id);
    this.setState({
      comment: false,
      deleteForm: false,
    });
  };

  render() {
    const { commentForm, deleteForm, comment } = this.state;
    return (
      <React.Fragment>
        <CommentGrid
          {...this.props}
          openForm={this.toggleCommentForm}
          openDeleteForm={this.toggleDeleteForm}
        />

        <CommentForm
          {...this.props}
          comment={comment}
          open={commentForm}
          handleClose={this.toggleCommentForm}
        />

        <DeleteDialog
          name="Comment"
          open={deleteForm}
          handleDelete={this.handleDelete}
          handleClose={this.toggleDeleteForm}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.currentUser,
  };
}

export default connect(mapStateToProps, {
  putComment,
  postComment,
  deleteComment,
})(Comments);
