import React from "react";

import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core/";
import { lighten, makeStyles } from "@material-ui/core/styles";
import AddSharpIcon from "@material-ui/icons/AddSharp";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import CreateNewFolderSharpIcon from "@material-ui/icons/CreateNewFolderSharp";
import LinkSharpIcon from "@material-ui/icons/LinkSharp";
import PublishSharpIcon from "@material-ui/icons/PublishSharp";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

export default function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();
  const {
    path,
    handleUpload,
    openLinkForm,
    openFolderForm,
    setActiveDirectory,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar className={classes.toolbar}>
      <Button
        variant="outlined"
        aria-haspopup="true"
        onClick={handleClick}
        aria-controls="file-menu"
        startIcon={<AddSharpIcon />}
      >
        New
      </Button>
      {path.map((x, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <ArrowForwardIosSharpIcon />}
          <Button
            onClick={() => {
              setActiveDirectory(x);
            }}
          >
            {x.label}
          </Button>
        </React.Fragment>
      ))}
      <Menu
        keepMounted
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            openFolderForm(false);
          }}
        >
          <ListItemIcon>
            <CreateNewFolderSharpIcon />
          </ListItemIcon>
          <Typography variant="inherit">New Folder</Typography>
        </MenuItem>
        <input
          id="upload"
          type="file"
          onChange={handleUpload}
          style={{ display: "none" }}
        />
        <label htmlFor="upload">
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PublishSharpIcon />
            </ListItemIcon>
            <Typography variant="inherit">Upload File</Typography>
          </MenuItem>
        </label>
        <MenuItem
          onClick={() => {
            handleClose();
            openLinkForm(false);
          }}
        >
          <ListItemIcon>
            <LinkSharpIcon />
          </ListItemIcon>
          <Typography variant="inherit">New Link</Typography>
        </MenuItem>
      </Menu>
    </Toolbar>
  );
}
