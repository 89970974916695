import { apiCall } from "../../services/api";
import { addToList, removeFromList, updateList } from "./lists";
import { addSnackbar } from "./snackbars";

export const postComment = (id, data) => async (dispatch) => {
  try {
    const res = await apiCall("post", `/api/projects/${id}/comments/`, data);
    dispatch(addToList(res, "comments"));
    dispatch(addSnackbar({ message: "Comment created" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const putComment = (id, commentID, data) => async (dispatch) => {
  try {
    const res = await apiCall(
      "put",
      `/api/projects/${id}/comments/${commentID}`,
      data
    );
    dispatch(updateList(res, "comments"));
    dispatch(addSnackbar({ message: "Comment updated" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};

export const deleteComment = (id, commentID) => async (dispatch) => {
  try {
    const res = await apiCall(
      "delete",
      `/api/projects/${id}/comments/${commentID}`
    );
    dispatch(removeFromList(res, "comments"));
    dispatch(addSnackbar({ message: "Comment removed" }, "success"));
  } catch (err) {
    dispatch(addSnackbar(err, "error"));
  }
};
