import React, { Component } from "react";
import { connect } from "react-redux";

import { Grid, Hidden } from "@material-ui/core/";

import { unsetFocus } from "../../store/actions/focus";
import {
  deleteProject,
  getProject,
  putProject,
} from "../../store/actions/projects";
import { addSnackbar } from "../../store/actions/snackbars";
import { getUsers, resetUsers } from "../../store/actions/users";
import Comments from "../Comments/";
import DeleteDialog from "../Common/DeleteDialog";
import Directory from "../Directory/";
import TimeLogs from "../TimeLogs/";
import ProjectCard from "./Card";
import { ProjectForm } from "./Form";
import UserSearch from "./UserSearch";

class ProjectShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      openForm: false,
      openDelete: false,
      timestamp: undefined,
      openUserSearch: false,
    };
  }

  async componentDidMount() {
    const { history, match, getProject } = this.props;
    await getProject(match.params.id, history);
  }

  componentWillUnmount() {
    this.props.unsetFocus();
  }

  toggleForm = () => {
    this.setState({
      openForm: !this.state.openForm,
    });
  };

  toggleDeleteForm = () => {
    this.setState({
      openDelete: !this.state.openDelete,
    });
  };

  handleDelete = async () => {
    const { history, project, deleteProject } = this.props;
    await deleteProject(history, project._id);
  };

  toggleUserSearch = () => {
    this.setState({ openUserSearch: !this.state.openUserSearch });
    this.props.resetUsers();
  };

  handleSearch = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });

    const { getUsers } = this.props;
    const query = {
      $and: [
        { $or: [{ accountType: "Estimator" }, { accountType: "Admin" }] },
        { username: { $regex: value, $options: "gi" } },
      ],
    };
    getUsers(query);
  };

  addEstimator = (estimator) => {
    const { putProject, project } = this.props;
    putProject(project._id, { estimator });
    this.toggleUserSearch();
    this.setState({ query: "" });
  };

  render() {
    const { timestamp, project, users } = this.props;
    const { openForm, openDelete, openUserSearch, query } = this.state;
    if (project) {
      return (
        <Grid
          container
          alignItems="flex-start"
          spacing={window.innerWidth >= 1280 ? 2 : 0}
        >
          <Grid container item xs={12} lg={8}>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <ProjectCard
                {...this.props}
                parent="show"
                openForm={this.toggleForm}
                openDeleteForm={this.toggleDeleteForm}
                toggleUserSearch={this.toggleUserSearch}
              />
            </Grid>

            <Hidden lgUp>
              <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Comments {...this.props} comments={project.comments} />
              </Grid>
            </Hidden>

            <Grid item xs={12} style={{ marginBottom: 16 }}>
              <Directory {...this.props} />
            </Grid>

            <Grid item xs={12}>
              <TimeLogs {...this.props} focusTimeLogs={project.timeLogs} />
            </Grid>
          </Grid>

          <Hidden mdDown>
            <Grid item xs={12} lg={4}>
              <Comments {...this.props} comments={project.comments} />
            </Grid>
          </Hidden>

          <ProjectForm
            {...this.props}
            open={openForm}
            timestamp={timestamp}
            handleClose={this.toggleForm}
          />

          <DeleteDialog
            open={openDelete}
            name={project.name}
            handleDelete={this.handleDelete}
            handleClose={this.toggleDeleteForm}
          />

          <UserSearch
            query={query}
            users={users}
            open={openUserSearch}
            handleSearch={this.handleSearch}
            addEstimator={this.addEstimator}
            handleClose={this.toggleUserSearch}
          />
        </Grid>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

function mapStateToProps(state) {
  return {
    users: state.users.list,
    currentUser: state.currentUser,
    timestamp: state.focus.timestamp,
    project: state.focus.item || false,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  unsetFocus,
  resetUsers,
  getProject,
  putProject,
  addSnackbar,
  deleteProject,
})(ProjectShow);
