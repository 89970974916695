import { ADD_USER, LOAD_USER, LOAD_USERS, REMOVE_USER } from "../actionTypes";

const DEFAULT_STATE = {
  list: [],
  loading: false,
  timestamp: false,
};

export const users = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case LOAD_USERS:
      return {
        list: [...action.users],
        loading: false,
        timestamp: new Date(),
      };
    case ADD_USER:
      return {
        ...state,
        list: [...state.list, action.user],
      };
    case LOAD_USER:
      if (state.timestamp) {
        return {
          ...state,
          list: state.list.map((user) =>
            user._id === action.user._id ? action.user : user
          ),
        };
      } else {
        return state;
      }
    case REMOVE_USER:
      return {
        ...state,
        list: state.list.filter((user) => user._id !== action.id),
      };
    case DEFAULT_STATE:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
